import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Typography,
} from '@mui/material';
import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { apiPostProductStats } from '../../../../api/apiProducts';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const CategorySelection = ({
                             availableCategories,
                             filterByCategory,
                             setCategory,
                             category,
                             homewareCategoryList,
                             setHomewareCategoryList,
                             selectedHomewareCategory,
                             setSelectedHomewareCategory,
                             isHomeware,
                             setIsHomeware
                           }: any) => {

  const argumentDataStructure = {
    data: {
      items_groups_stats: [],
    }
  };



  const {
    data: homewareData,
    isLoading,
    error
  } = useQuery<any, Error>(
    ['application-homeware'],
    () => apiPostProductStats(argumentDataStructure),
    {
      onSuccess: (data) => {

        if (data?.homeware) {
          createHomewareList(data);
        }
      },
      onError: (error) => {
        console.error('Error fetching homeware data:', error);
      }
    }
  );

  const createHomewareList = (data: any) => {

    if (!data?.homeware) {
      console.warn('No homeware data available');
      return;
    }

    const homewareKeys = Object.keys(data.homeware);
    const updatedList: any = [];

    homewareKeys.forEach((key) => {
      switch (key) {
        case 'Bathroom':
          updatedList.push({ category: "Bathroom", itemGroup: '016' });
          break;
        case 'Bedroom':
          updatedList.push({ category: "Bedroom", itemGroup: '006' });
          break;
        case 'Curtains':
          updatedList.push({ category: "Curtains", itemGroup: '015' });
          break;
        case 'Decorative':
          updatedList.push({ category: "Decorative", itemGroup: '010' });
          break;
        case 'Furniture':
          updatedList.push({ category: "Furniture", itemGroup: '009' });
          break;
        case 'Illumination':
          updatedList.push({ category: "Illumination", itemGroup: '007' });
          break;
        case 'Scatters':
          updatedList.push({ category: "Scatters", itemGroup: '018' });
          break;
        case 'Tableware':
          updatedList.push({ category: "Tableware", itemGroup: '008' });
          break;
        case 'Throws, Quilts and Furs':
          updatedList.push({ category: 'Throws, Quilts and Furs', itemGroup: '013' });
          break;
        case 'Wall Decor':
          updatedList.push({ category: 'Wall Decor', itemGroup: '014' });
          break;
      }
    });


    setHomewareCategoryList(updatedList);
  };

  // Initialize category only once when component mounts
  useEffect(() => {
    if (availableCategories?.length > 0) {

      setCategory(availableCategories[0].itemGroup);
      // Only call filterByCategory if it's not a Homeware category
      if (availableCategories[0].category !== 'Homeware') {
        filterByCategory(availableCategories[0].itemGroup);
      }
    }
  }, []); // Empty dependency array to run only once

  return (
    <>
      <Divider sx={{ backgroundColor: '#000000', mb: '1rem' }} />
      <Typography fontWeight='bold' fontSize='20px' mb={2}>
        Categories
      </Typography>

      <Box sx={{ overflowY: 'auto', maxHeight: '20.5rem' }}>
        <FormGroup>
          {availableCategories
            ?.sort()
            .map((elem: any, index: number) => (
              <Box
                key={`category-${elem.itemGroup}-${index}`}
                sx={{ display: 'flex', alignItems: 'center' }}
              >
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={category === elem.itemGroup}
                        onChange={() => {

                          setCategory(elem.itemGroup);
                          if (elem.category !== 'Homeware') {
                            filterByCategory(elem.itemGroup);
                            setIsHomeware(false);
                          }
                          if (elem.category === 'Homeware') {
                            setIsHomeware(true);
                            setSelectedHomewareCategory('');
                          }
                        }}
                      />
                    }
                    label={elem.category}
                  />
                </FormGroup>
                {elem.category === 'Homeware' && (
                  isHomeware ?
                    <ArrowDropDownIcon sx={{ ml: -2 }} /> :
                    <ArrowRightIcon sx={{ ml: -2 }} />
                )}
              </Box>
            ))}
        </FormGroup>

        {isHomeware && homewareCategoryList && (
          <FormGroup sx={{ ml: 3.5 }}>
            {homewareCategoryList
              ?.sort()
              .map((elem: any, index: number) => (
                <FormGroup key={`homeware-${elem.itemGroup}-${index}`}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedHomewareCategory === elem.itemGroup}
                        onChange={() => {

                          setSelectedHomewareCategory(elem.itemGroup);
                          filterByCategory(elem.itemGroup);
                        }}
                      />
                    }
                    label={elem.category}
                  />
                </FormGroup>
              ))}
          </FormGroup>
        )}
      </Box>
    </>
  );
};

export default CategorySelection;