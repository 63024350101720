import Filter from './PromoFilter';
import { Box, Button, Divider, Typography, useMediaQuery } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useEffect, useState } from 'react';

interface FilterCategory {
    title: string;
    available: any[];
    filtered: any[];
    list: string[];
    setList: React.Dispatch<React.SetStateAction<string[]>>;
    keyName: string;
    paramKey: string;
}

const PromoFilterSelection = ({
                                  // Available options
                                  availableColours,
                                  availableDesigns,
                                  availableTypes,
                                  availableBrands,
                                  availableMaterials,
                                  availableStandards,
                                  availableSizes,
                                  availableShapes,

                                  // Filtered options
                                  filteredColours,
                                  filteredDesigns,
                                  filteredTypes,
                                  filteredBrands,
                                  filteredMaterials,
                                  filteredStandards,
                                  filteredSizes,
                                  filteredShapes,

                                  // Selected values
                                  colourList,
                                  designList,
                                  typesList,
                                  brandList,
                                  materialList,
                                  standardsList,
                                  sizeList,
                                  shapeList,

                                  // State setters
                                  setColourList,
                                  setDesignList,
                                  setTypesList,
                                  setBrandList,
                                  setMaterialList,
                                  setStandardsList,
                                  setSizeList,
                                  setShapeList,

                                  // Other props
                                  handleCheckboxChange,
                                  applyFilters,
                                  applicationsList,
                                  itemGroup,
                              }: any) => {
    // Initialize visibility state for all filter sections
  const [visibility, setVisibility] = useState<{ [key: string]: boolean }>({
    Colour: false,
    Design: false,
    Types: false,
    Brand: false,
    Material: false,
    Standards: false,
    Size: false,
    Shape: false,
  });

    const isMobile = useMediaQuery('(max-width:600px)');

    // Toggle all sections closed on mobile
    useEffect(() => {
        if (isMobile) {
            setVisibility(prevState =>
                Object.keys(prevState).reduce((acc, key) => ({
                    ...acc,
                    [key]: false
                }), {})
            );
        }
    }, [isMobile]);

    const filterCategories: FilterCategory[] = [
        {
            title: 'Colour',
            available: availableColours,
            filtered: filteredColours,
            list: colourList,
            setList: setColourList,
            keyName: 'colour',
            paramKey: 'colour',
        },
        {
            title: 'Design',
            available: availableDesigns,
            filtered: filteredDesigns,
            list: designList,
            setList: setDesignList,
            keyName: 'design',
            paramKey: 'design',
        },
        {
            title: 'Types',
            available: availableTypes,
            filtered: filteredTypes,
            list: typesList,
            setList: setTypesList,
            keyName: 'type',
            paramKey: 'type',
        },
        {
            title: 'Brand',
            available: availableBrands,
            filtered: filteredBrands,
            list: brandList,
            setList: setBrandList,
            keyName: 'brand',
            paramKey: 'brand',
        },
        {
            title: 'Material',
            available: availableMaterials,
            filtered: filteredMaterials,
            list: materialList,
            setList: setMaterialList,
            keyName: 'material',
            paramKey: 'material',
        },
        {
            title: 'Standards',
            available: availableStandards,
            filtered: filteredStandards,
            list: standardsList,
            setList: setStandardsList,
            keyName: 'standard',
            paramKey: 'standard',
        },
        {
            title: 'Size',
            available: availableSizes,
            filtered: filteredSizes,
            list: sizeList,
            setList: setSizeList,
            keyName: '',
            paramKey: 'size',
        },
        {
            title: 'Shape',
            available: availableShapes,
            filtered: filteredShapes,
            list: shapeList,
            setList: setShapeList,
            keyName: '',
            paramKey: 'shape',
        },
    ];

    const getFilteredOptions = (available: any[], filtered: any[], category: string) => {
        if (!applicationsList?.length || !available?.length) {
            return available;
        }

        if (!filtered?.length && available?.length) {
            // First remove any dashes and empty values
            let cleanedAvailable = available.filter(item => {
                if (typeof item === 'string') {
                    return item !== '-' && item.trim() !== '';
                }
                return item && item.type !== '-' && item.type !== '';
            });

            // Remove duplicates using Set
            if (category === 'Types') {
                const uniqueTypes = new Set(cleanedAvailable.map(item =>
                    typeof item === 'string' ? item.trim() : item.type.trim()
                ));
                cleanedAvailable = Array.from(uniqueTypes).map(type =>
                    typeof type === 'string' ? type : { type }
                );
            }

            return cleanedAvailable;
        }

        if (category === 'Size' || category === 'Shape') {
            // Handle size and shape categories
            const uniqueValues = new Set(available
                .map(item => typeof item === 'string' ? item.toLowerCase().trim() : item)
                .filter(item => item !== '-' && item !== '')
            );
            const lowerFiltered = filtered.map((sz: string) => sz.toLowerCase());
            return Array.from(uniqueValues)
                .filter((item) => lowerFiltered.includes(typeof item === 'string' ? item : item.toLowerCase()));
        }

        // Handle all other categories
        const uniqueValues = new Set(available.filter(item => {
            if (typeof item === 'string') {
                return item !== '-' && item.trim() !== '';
            }
            return item && item.type !== '-' && item.type.trim() !== '';
        }));

        return Array.from(uniqueValues).filter((item) => filtered.includes(item));
    };

    return (
        <>
            {filterCategories.map((category) => {
                const filteredOptions = getFilteredOptions(
                    category.available,
                    category.filtered,
                    category.title
                );

                if (!filteredOptions?.length ||
                    (category.title === 'Shape' && itemGroup !== '004')) {
                    return null;
                }

                return (
                    <Box key={category.title}>
                        <Divider sx={{ backgroundColor: '#000000', m: '1rem 0' }} />
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Typography fontWeight="bold" fontSize="20px" mb={2}>
                                {category.title}
                            </Typography>
                            <Box
                                onClick={() => setVisibility(prev => ({
                                    ...prev,
                                    [category.title]: !prev[category.title]
                                }))}
                                sx={{ cursor: 'pointer' }}
                            >
                                {visibility[category.title] ?
                                    <KeyboardArrowDownIcon /> :
                                    <KeyboardArrowUpIcon />
                                }
                            </Box>
                        </Box>
                        {visibility[category.title] && (
                            <Filter
                                filterType={filteredOptions}
                                filteredOptions={category.filtered}
                                handleCheckboxChange={handleCheckboxChange}
                                list={category.list}
                                setList={category.setList}
                                keyName={category.keyName}
                                paramKey={category.paramKey}
                            />
                        )}
                    </Box>
                );
            })}

            <Divider sx={{ backgroundColor: '#000000', m: '1rem 0' }} />
            <Button
                variant="contained"
                onClick={() => {
                    // Call applyFilters with current filter values
                    applyFilters();
                }}
                sx={{ width: { xs: '100%', lg: '50%' } }}
                disableElevation
            >
                Apply
            </Button>
            <Divider sx={{ backgroundColor: '#000000', m: '1rem 0' }} />
        </>
    );
};

export default PromoFilterSelection;