import { baseURL } from './baseUrl';
import { ensureAuthTokenLoaded } from '../utilities/AuthUtils';

export const apiSearch = async (searchTerm: string) => {


    const response = await baseURL.get(`search?query=${searchTerm}`, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
    return response.data;
};

export const resourcesSearch = async (searchTerm: string) => {
    // Wait for auth token to be available
    const authToken = await ensureAuthTokenLoaded();

    const response = await baseURL.get(`resources/search?query=${searchTerm}`, {
        headers: {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json',
        },
    });
    return response.data;
};