import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { CategorySettings, PromoLayout } from '../types';

interface PromoBannerProps {
  categorySettings?: CategorySettings;
  layout?: PromoLayout;
}

const NavigationButton = styled(Box)(({ theme }) => ({
  position: 'absolute',
  bottom: '50%',
  zIndex: 1,
  cursor: 'pointer',
  '& svg': {
    color: theme.palette.common.white
  }
}));

const PromoBanner: React.FC<PromoBannerProps> = ({ categorySettings, layout }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const videoRef = useRef<HTMLVideoElement>(null);
  const bannerContent = categorySettings || layout;

  const mediaType = bannerContent?.banner_media_type || layout?.landing_page_banner_media_type;
  const bannerImage = categorySettings?.banner_img || layout?.banner_img;
  const bannerVideo = categorySettings?.banner_video || layout?.landing_page_video;
  const carouselData = categorySettings?.banner_carousel_data || layout?.landing_page_carousel_data;

  const next = () => setCurrentIndex(prev => (prev + 1) % (carouselData?.length || 1));
  const previous = () => setCurrentIndex(prev => (prev - 1 + (carouselData?.length || 1)) % (carouselData?.length || 1));

  return (
    <Box
      sx={{
        width: '100%',
        position: 'relative',
        overflow: 'hidden',
        paddingTop: '56.25%',
      }}
    >
      {mediaType === 'video' && bannerVideo ? (
        <video
          ref={videoRef}
          style={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
            objectFit: 'contain',
          }}
          muted
          autoPlay
          playsInline
        >
          <source src={bannerVideo} type="video/mp4" />
        </video>
      ) : mediaType === 'carousel' && carouselData?.length ? (
        <>
          {carouselData.map((item, index) => (
            <Box
              key={index}
              sx={{
                width: '100%',
                height: '100%',
                position: 'absolute',
                top: 0,
                left: 0,
                opacity: index === currentIndex ? 1 : 0,
                transition: 'opacity 0.5s ease-in-out',
              }}
            >
              <img
                style={{
                  width: '100%',
                  height: '100%',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  objectFit: 'contain',
                }}
                src={item.media_url}
                alt={item.media_name || ''}
              />
            </Box>
          ))}
          {carouselData.length > 1 && (
            <>
              <NavigationButton sx={{ left: '5%' }} onClick={previous}>
                <ArrowBackIosIcon />
              </NavigationButton>
              <NavigationButton sx={{ right: '5%' }} onClick={next}>
                <ArrowForwardIosIcon />
              </NavigationButton>
            </>
          )}
        </>
      ) : bannerImage ? (
        <img
          style={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
            objectFit: 'contain',
          }}
          src={bannerImage}
          alt="Banner"
        />
      ) : (
        <Box>No content available</Box>
      )}
    </Box>
  );
};

export default PromoBanner;