import { Box, Typography } from "@mui/material";

const MissingContent = ({ sectionName }: any) => {
  const url = window.location.origin;

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {url.includes("staging") || url.includes("localhost") ? (
        <Box
          sx={{
            // width: "70%",
            // display: "flex",
            // justifyContent: "center",
            // alignItems: "center",
            // flexDirection: "column",
            // mt: 10,
            // mb: 10,
            // p: 10,
            // border: "red solid 1px",
          }}
        >
          {/*<Typography fontWeight={"bold"} fontSize={"2rem"}>*/}
          {/*  Missing Content for{" "}*/}
          {/*  <span style={{ textDecorationLine: "underline" }}>*/}
          {/*    {sectionName}*/}
          {/*  </span>*/}
          {/*</Typography>*/}
          {/*<Typography fontWeight={"bold"} fontSize={"2rem"}>*/}
          {/*  Please upload content for this section on the Hub*/}
          {/*</Typography>*/}
        </Box>
      ) : null}
    </Box>
  );
};

export default MissingContent;
