import { useQuery, useQueryClient } from 'react-query';
import { useParams, useLocation, useSearchParams } from 'react-router-dom';
import React, { useEffect, useRef } from 'react';

import PageLayout from "../../../layout/PageLayout";
import PromoBanner from '../promoLayout/PromoCarousel';
import PromoFilterComponent from "../promoFilter/PromoFilterComponent";
import LoadingScreen from "../../../loadingScreen/LoadingScreen";
import MissingPage from "../../../errorMessages/MissingPage";
import { capitalizeString } from "../../../../../utilities/TextManipulation";
import { captureAnalytics } from "../../../../../utilities/Analytics";
import {
    PromoApiResponse,
    PromoFiltersResponse,
    MainSettingsResponse,
    PromoLayout,
    MediaType
} from "../types";
import {
    apiGetFilteredPromoProducts,
    apiGetFiltersForItemGroup,
    apiGetPromoLayout
} from "../../../../../api/apiPromos";

function PromoApplicationTemplate() {
    const { application } = useParams<{ application: string }>();
    const [searchParams] = useSearchParams();
    const itemGroup = searchParams.get('itemGroup');
    const location = useLocation();
    const queryClient = useQueryClient();

    // Reference to track if this is initial render
    const isInitialMount = useRef(true);

    // Detect Safari browser
    const isSafari = useRef(
      typeof window !== 'undefined' &&
      /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
    );

    // Get main settings for the banner
    const {
        data: mainSettingsData,
        isLoading: isLoadingMainSettings
    } = useQuery<MainSettingsResponse>(
      ['promo-layout', application, itemGroup],
      () => apiGetPromoLayout(1),
      {
          enabled: !!application && !!itemGroup,
          staleTime: 5 * 60 * 1000, // 5 minutes
          keepPreviousData: true
      }
    );

    // Get all available filters for the item group
    const {
        data: filterData,
        isLoading: isLoadingFilters
    } = useQuery<PromoFiltersResponse>(
      ['promo-filters', itemGroup],
      () => apiGetFiltersForItemGroup(itemGroup || ''),
      {
          enabled: !!itemGroup,
          staleTime: 5 * 60 * 1000, // 5 minutes
          keepPreviousData: true
      }
    );

    // Get initial products without application filter
    const {
        data: promoData,
        isLoading: isLoadingProducts,
        isError: isProductError
    } = useQuery<PromoApiResponse>(
      ['promo-products', itemGroup],
      () => apiGetFilteredPromoProducts(
        itemGroup || '',
        {}, // No initial filters
        0,
        50
      ),
      {
          enabled: !!itemGroup,
          staleTime: 5 * 60 * 1000, // 5 minutes
          keepPreviousData: true
      }
    );

    const isLoading = isLoadingFilters || isLoadingProducts || isLoadingMainSettings;
    const isError = isProductError || !promoData?.success;

    // Fixed scroll restoration effect
    useEffect(() => {
        if ('scrollRestoration' in window.history) {
            // Set to manual for more control
            window.history.scrollRestoration = 'manual';
        }

        return () => {
            if ('scrollRestoration' in window.history) {
                // Reset on component unmount
                window.history.scrollRestoration = 'auto';
            }
        };
    }, []);

    // Improved scroll handling effect
    useEffect(() => {
        // Only run scroll logic after the first render
        if (isInitialMount.current) {
            isInitialMount.current = false;
            return;
        }

        // Determine if this is a user-initiated navigation (not back/forward)
        const isNewNavigation = !location.key || location.state?.scrollToTop;

        if (isNewNavigation) {
            // For new navigation, scroll to top
            window.scrollTo(0, 0);
        } else if (isSafari.current) {
            // For Safari back navigation, use a short timeout to ensure proper behavior
            const scrollPosition = window.sessionStorage.getItem(`scroll_${location.key}`);

            if (scrollPosition) {
                setTimeout(() => {
                    window.scrollTo(0, parseInt(scrollPosition, 10));
                }, 10);
            }
        }
    }, [location]);

    // Save scroll position before navigating away
    useEffect(() => {
        if (location.key && isSafari.current) {
            const handleBeforeUnload = () => {
                window.sessionStorage.setItem(
                  `scroll_${location.key}`,
                  window.scrollY.toString()
                );
            };

            window.addEventListener('beforeunload', handleBeforeUnload);

            return () => {
                window.removeEventListener('beforeunload', handleBeforeUnload);
            };
        }
    }, [location.key]);

    // Analytics tracking
    useEffect(() => {
        if (application) {
            captureAnalytics('pageview', location.pathname, `promo-${application}`);
        }
    }, [location.pathname, application]);

    if (!itemGroup) {
        return <MissingPage />;
    }

    // Find the products for the current application
    const applicationProducts = promoData?.promo_products?.filter(
      p => p.application === application
    ) || [];

    // Find the matching category settings from main settings based on the selected category name
    const categorySettings = mainSettingsData?.main_settings?.category_settings?.find(
      category =>
        category.category_name.toLowerCase() === application?.toLowerCase() &&
        category.is_active === 1 &&
        category.is_deleted === 0
    );

    const firstProduct = applicationProducts[0] || promoData?.promo_products?.[0];
    const maxDiscount = Math.max(
      ...(applicationProducts.map(p => p.discount_percentage) || [0])
    );

    const fallbackLayout = !categorySettings && firstProduct ? {
        // Text content
        banner_text: `${capitalizeString(application || '')} - Up to ${Math.abs(maxDiscount).toFixed(0)}% OFF`,
        banner_text_colour: '#000000',
        banner_text_size: '32',
        banner_sub_text: 'On Sale Now',
        banner_sub_text_colour: '#FFFFFF',
        banner_sub_text_size: '24',
        banner_date_text: '',
        banner_date_text_color: '#FFFFFF',
        banner_date_text_size: '14',

        // Image and URL
        banner_img: firstProduct.primary_image,
        banner_url: null,
        banner_size_x: '2800',
        banner_size_y: '1600',

        // Status
        is_active: 1,
        is_live: 1,

        // Media types
        landing_page_banner_media_type: 'image',
        banner_media_type: 'image' as MediaType,

        // Video content
        landing_page_video: undefined,
        banner_video: undefined,
        banner_video_name: undefined,

        // Carousel data
        landing_page_carousel_data: [],
        banner_carousel_data: []
    } as PromoLayout : undefined;

    return (
      <PageLayout>
          {isLoading && <LoadingScreen />}
          {!isLoading && (
            <>
                <PromoBanner categorySettings={categorySettings} layout={fallbackLayout} />
                <PromoFilterComponent
                  title={`${capitalizeString(application || '')} on Sale`}
                  itemGroup={itemGroup}
                  initialFilters={application ? { application: [application] } : undefined}
                  availableFilters={filterData?.filters}
                  totalCount={promoData?.total_count}
                  categorySettings={categorySettings}
                />
            </>
          )}
          {!isLoading && !promoData?.promo_products?.length && (
            <div>No promotional products found for this application.</div>
          )}
      </PageLayout>
    );
}

export default PromoApplicationTemplate;