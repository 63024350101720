export const ensureAuthTokenLoaded = (): Promise<string> => {
  return new Promise((resolve) => {
    const checkToken = () => {
      const token = localStorage.getItem('authToken');
      if (token) {
        resolve(token);
      } else {
        // Check again in 100ms
        setTimeout(checkToken, 100);
      }
    };
    checkToken();
  });
};