import { cartBaseURL } from './baseUrl';

export const apiGetOrders = async (
  authToken: string | null,
  isProcessed: boolean
) => {
  const response = await cartBaseURL.get(
    `/orders?processed=${isProcessed}`,
    {
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
    }
  );
  return response.data;
};

export const apiGetOrdersStats = async (authToken: string | null) => {
  const response = await cartBaseURL.get(`/orders?stats=true`, {
    headers: {
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'application/json',
    },
  });
  return response.data;
};