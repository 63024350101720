import { Box, Divider, Typography } from '@mui/material';

interface PromoClearFiltersProps {
    clearFilters: () => void;
}

const PromoClearFilters = ({ clearFilters }: PromoClearFiltersProps) => {
    const handleClearClick = (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        clearFilters();
    };

    return (
        <>
            <Divider sx={{ backgroundColor: '#000000', m: '1rem 0' }} />
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <Typography
                    fontWeight='bold'
                    mb={2}
                    sx={{ fontSize: { xs: '20px', sm: '24px' } }}
                >
                    Filters
                </Typography>
                <Typography
                    fontWeight='bold'
                    fontSize='20px'
                    mb={2}
                    sx={{
                        cursor: 'pointer',
                        fontSize: { xs: '20px', sm: '24px' },
                        '&:hover': {
                            opacity: 0.8,
                        },
                        transition: 'opacity 0.2s'
                    }}
                    onClick={handleClearClick}
                    role="button"
                    tabIndex={0}
                >
                    Clear
                </Typography>
            </Box>
        </>
    );
};

export default PromoClearFilters;