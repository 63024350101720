import { baseURL } from './baseUrl';
import { ensureAuthTokenLoaded } from '../utilities/AuthUtils';
import {
    PromoApiResponse,
    PromoFilters,
    PromoApplicationData,
    PromoFiltersResponse,
    MainSettingsResponse,
    PromoProductResponse,
    PromoProduct
} from "../components/shared/pageTemplates/promo/types";

// Define the response type for getAllPromoProducts
interface AllPromoProductsResponse {
    success: boolean;
    code: number;
    msg: string;
    promo_products: PromoProduct[];
}

export const apiGetAllPromoProducts = async (): Promise<AllPromoProductsResponse> => {
    // Wait for auth token to be available
    const authToken = await ensureAuthTokenLoaded();

    try {
        const response = await baseURL.get('promo-products/get-all-promo-products', {
            headers: {
                Authorization: `Bearer ${authToken}`,
                'Content-Type': 'application/json',
            },
        });
        return response.data;
    } catch (error: unknown) {
        if (error &&
          typeof error === 'object' &&
          'response' in error &&
          error.response &&
          typeof error.response === 'object' &&
          'status' in error.response) {

            if (error.response.status === 502) {
                throw new Error('Network error: Unable to connect to the server');
            }
        }
        throw error instanceof Error ? error : new Error('An unknown error occurred');
    }
};

export const apiGetFilteredPromoProducts = async (
  itemGroup: string | string[],
  filters: PromoFilters,
  offset: number,
  limit: number
): Promise<PromoApiResponse> => {
    // Wait for auth token to be available
    const authToken = await ensureAuthTokenLoaded();

    if (!itemGroup || (Array.isArray(itemGroup) && itemGroup.length === 0) || itemGroup === '') {
        throw new Error('Item group is required');
    }

    try {
        // Send the full itemGroup array or string
        const response = await baseURL.post('promo-products/get-promo-products-filtered', {
            item_group: itemGroup,
            filters: filters || {},
            offset: offset || 0,
            limit: limit || 9
        }, {
            headers: {
                Authorization: `Bearer ${authToken}`,
                'Content-Type': 'application/json',
            },
        });
        return response.data;
    } catch (error: unknown) {
        if (error &&
          typeof error === 'object' &&
          'response' in error &&
          error.response &&
          typeof error.response === 'object' &&
          'status' in error.response) {

            if (error.response.status === 502) {
                throw new Error('Network error: Unable to connect to the server');
            }
        }
        throw error instanceof Error ? error : new Error('An unknown error occurred');
    }
};

export const apiGetFiltersForItemGroup = async (itemGroup: string): Promise<PromoFiltersResponse> => {
    // Wait for auth token to be available
    const authToken = await ensureAuthTokenLoaded();

    try {
        const response = await baseURL.get(`promo-products/get-promo-product-filters-for-item-group/${itemGroup}`, {
            timeout: 10000,
            headers: {
                Authorization: `Bearer ${authToken}`,
                'Content-Type': 'application/json',
            },
        });
        return response.data;
    } catch (error: unknown) {
        // Properly type check the error
        if (error &&
          typeof error === 'object' &&
          'code' in error &&
          error.code === 'ECONNABORTED') {

            // If it's a timeout error, retry once more with a longer timeout
            console.log('Request timed out, retrying...');
            // Wait for auth token again for the retry
            const retryAuthToken = await ensureAuthTokenLoaded();
            const retryResponse = await baseURL.get(`promo-products/get-promo-product-filters-for-item-group/${itemGroup}`, {
                timeout: 20000,
                headers: {
                    Authorization: `Bearer ${retryAuthToken}`,
                    'Content-Type': 'application/json',
                },
            });
            return retryResponse.data;
        }

        // For other errors, just rethrow
        throw error;
    }
};

export const apiGetPromoProductBySku = async (sku: string): Promise<PromoProductResponse> => {
    // Wait for auth token to be available
    const authToken = await ensureAuthTokenLoaded();

    try {
        const response = await baseURL.get(`promo-products/get-promo-product-by-sku/${sku}`, {
            headers: {
                Authorization: `Bearer ${authToken}`,
                'Content-Type': 'application/json',
            },
        });
        return response.data;
    } catch (error: unknown) {
        if (error &&
          typeof error === 'object' &&
          'response' in error &&
          error.response &&
          typeof error.response === 'object' &&
          'status' in error.response) {

            if (error.response.status === 502) {
                throw new Error('Network error: Unable to connect to the server');
            }
        }
        throw error instanceof Error ? error : new Error('An unknown error occurred');
    }
};

export const apiGetApplicationsForItemGroup = async (itemGroup: string): Promise<string[]> => {
    const response = await apiGetFiltersForItemGroup(itemGroup);
    return response.filters?.application || [];
};

export const apiGetPromoApplicationData = async (application: string): Promise<PromoApplicationData> => {
    // Wait for auth token to be available
    const authToken = await ensureAuthTokenLoaded();

    const response = await baseURL.get(`promo-products/get-application-data/${application}`, {
        headers: {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json',
        },
    });
    return response.data;
};

export const apiGetMainSettings = async (settingsId: number = 1): Promise<MainSettingsResponse> => {
    // Wait for auth token to be available
    const authToken = await ensureAuthTokenLoaded();

    const response = await baseURL.get(`page-settings/get-main-settings-by-id/${settingsId}`, {
        headers: {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json',
        },
    });
    return response.data;
};

export const apiGetPromoLayout = async (settingsId: number): Promise<MainSettingsResponse> => {
    return await apiGetMainSettings(settingsId);
};