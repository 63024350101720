
import React from 'react';
import PageLayout from "../../../layout/PageLayout";
import PromoContent from './PromoContent';

const PromoLanding: React.FC = () => {
    return (
        <PageLayout>
            <PromoContent />
        </PageLayout>
    );
};

export default PromoLanding;