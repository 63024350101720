import {
  Typography,
  Grid,
  Button,
  Divider,
  Box,
  Modal,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  CircularProgress,
} from '@mui/material';
import { useShoppingCart } from '../../../context/ShoppingCartContext';
import { useState, useEffect } from 'react';
import { useAuth } from '../../../context/AuthContext';
import { useMutation, useQuery } from 'react-query';
import HertexLogo from '../../../assets/logos/Hertex_Icon.jpg';
import { useNavigate } from 'react-router-dom';
import { updateCartStatus } from '../../../api/apiCart';

function EditDetails({ previousStep }: any) {
  const navigate = useNavigate();
  const { user, isLogin, paymentTerms } = useAuth();
  const authToken = localStorage.getItem('authToken') || '';

  const {
    total,
    loadingItemId,
    cartService,
    cartItemData,
    updateDeliveryInfo,
  } = useShoppingCart();

  // Use payment terms from auth context (extracted from JWT)
  const canUseAccountPayment = paymentTerms === '30E' || cartItemData?.type === '30E';
  const isPaymentTypeLoading = !cartItemData || paymentTerms === null;

  // Set default payment method based on user's payment terms
  const [paymentMethod, setPaymentMethod] = useState(paymentTerms === '30E' ? '30E' : 'PIA');

  // Update payment method when payment terms change
  useEffect(() => {
    if (paymentTerms) {
      setPaymentMethod(paymentTerms === '30E' ? '30E' : 'PIA');
    }
  }, [paymentTerms]);

  // Store payment method in localStorage
  useEffect(() => {
    localStorage.setItem('paymentMethod', paymentMethod);
  }, [paymentMethod]);

  const { data: creditData } = useQuery('credit', () =>
    cartService.getCreditUser()
  );

  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const paymentMutation = useMutation(
    async () => {
      // Store the cart ID before initiating payment
      if (cartItemData?.id) {
        localStorage.setItem('lastProcessedCartId', cartItemData.id);
      }
      return await cartService.finalizeUserCartWithPayfast(cartItemData?.id);
    },
    {
      onSuccess: (data) => {
        if (data && data.payment_form) {
          // Insert the payment form into the payment container
          const paymentContainer = document.getElementById('payment-container');
          if (paymentContainer) {
            paymentContainer.innerHTML = data.payment_form;

            // Auto-submit the form after a short delay
            setTimeout(() => {
              const form = paymentContainer.querySelector('form');
              if (form) form.submit();
            }, 500);
          } else {
            console.error('Payment container not found');
            handleClose();
          }
        } else {
          console.error('Payment form data not received from server', data);
          handleClose();
        }
      },
      onError: (error) => {
        console.error('Payment redirect failed:', error);
        handleClose();
      },
    }
  );

  const handlePayment = async () => {
    try {
      // First, ensure the cart payment type matches the selected payment method
      if (cartItemData?.type !== paymentMethod) {
        // Get the current delivery info from cart
        const deliveryInfo = cartItemData?.orders_shipping_address;

        if (deliveryInfo) {
          // Update delivery info with the selected payment method
          await updateDeliveryInfo(
            {
              address: deliveryInfo.address_line_1,
              apartment: deliveryInfo.address_line_2,
              suburb: deliveryInfo.address_line_3,
              city: deliveryInfo.address_line_4,
              province: (deliveryInfo.address_line_5 || '').split(',')[0]?.trim() || '',
              country: (deliveryInfo.address_line_5 || '').split(',')[1]?.trim() || 'South Africa',
              postalCode: deliveryInfo.address_zip,
              name: deliveryInfo.contact_person?.name || '',
              surname: deliveryInfo.contact_person?.surname || '',
            },
            deliveryInfo.delivery_type,
            cartItemData.customer_order_num,
            '', // delivery notes
            {
              name: deliveryInfo.contact_person?.name || '',
              surname: deliveryInfo.contact_person?.surname || '',
              phone: deliveryInfo.contact_person?.phone || '',
            },
            paymentMethod as '30E' | 'PIA'
          );
        }
      }

      // Store the cart ID before initiating PayFast payment
      if (cartItemData?.id) {
        localStorage.setItem('lastProcessedCartId', cartItemData.id);
        console.log('Stored cart ID before PayFast payment:', cartItemData.id);
      }

      // Then update cart status and proceed with payment
      await updateCartStatus(cartItemData?.id, authToken, 'PROCESSING');
      handleOpen();
      paymentMutation.mutate();
    } catch (error) {
      console.error('Error updating cart status:', error);
    }
  };

  const [isLoading, setIsLoading] = useState(false);

  const creditPayMutation = useMutation(
    async () => {
      setIsLoading(true);
      // Store the cart ID before initiating payment
      if (cartItemData?.id) {
        localStorage.setItem('lastProcessedCartId', cartItemData.id);
      }
      return await cartService.finalizeUserCartWithAccount(cartItemData?.id);
    },
    {
      onSuccess: async (data) => {
        const checkStatus = async (
          attempts: number = 40,
          interval: number = 500
        ) => {
          // If cart not found after several attempts, payment likely succeeded
          try {
            const status = await cartService.getPaymentStatus(cartItemData?.id);
            if (status.cart_status === 'FINALIZED_CART') {
              navigate('/success');
            } else if (attempts > 0) {
              setTimeout(() => checkStatus(attempts - 1, interval), interval);
            } else {
              throw new Error('Payment status check timed out');
            }
          } catch (error: any) { // Type the error as 'any' to access message
            console.error('Error fetching payment status:', error);

            // Check for "Cart not found" in various possible error formats
            const errorMessage =
              typeof error === 'string' ? error :
                error?.message ||
                error?.response?.data?.error ||
                '';

            // If cart not found after several attempts, payment likely succeeded
            if (attempts < 35 && errorMessage.includes('Cart not found')) {
              navigate('/success');
            } else {
              navigate('/failed');
            }
          }
        };

        checkStatus();
      },
      onError: (error) => {
        console.error(error);
        navigate('/failed');
        setIsLoading(false);
      },
    }
  );

  // Modified function - now just opens the modal without triggering payment
  const handleCreditPayment = async () => {
    try {
      // First, ensure the cart payment type matches the selected payment method
      if (cartItemData?.type !== paymentMethod) {
        // Get the current delivery info from cart
        const deliveryInfo = cartItemData?.orders_shipping_address;

        if (deliveryInfo) {
          // Update delivery info with the selected payment method
          await updateDeliveryInfo(
            {
              address: deliveryInfo.address_line_1,
              apartment: deliveryInfo.address_line_2,
              suburb: deliveryInfo.address_line_3,
              city: deliveryInfo.address_line_4,
              province: (deliveryInfo.address_line_5 || '').split(',')[0]?.trim() || '',
              country: (deliveryInfo.address_line_5 || '').split(',')[1]?.trim() || 'South Africa',
              postalCode: deliveryInfo.address_zip,
              name: deliveryInfo.contact_person?.name || '',
              surname: deliveryInfo.contact_person?.surname || '',
            },
            deliveryInfo.delivery_type,
            cartItemData.customer_order_num,
            '', // delivery notes
            {
              name: deliveryInfo.contact_person?.name || '',
              surname: deliveryInfo.contact_person?.surname || '',
              phone: deliveryInfo.contact_person?.phone || '',
            },
            paymentMethod as '30E' | 'PIA'
          );
        }
      }

      // Then update cart status and open the modal
      await updateCartStatus(cartItemData?.id, authToken, 'PROCESSING');

      // ONLY open the modal - do NOT trigger payment here
      handleOpen();

      // Payment will now be triggered only when the user clicks "Pay Now" in the modal
    } catch (error) {
      console.error('Error updating cart status:', error);
    }
  };

  const handlePaymentMethodChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPaymentMethod((event.target as HTMLInputElement).value);
  };

  const renderPayWithCreditScreen = () => (
    <>
      <Button
        variant="contained"
        disableElevation
        sx={{
          px: '10px',
        }}
        onClick={handleCreditPayment}
        disabled={loadingItemId !== null}
      >
        Continue to pay on account
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="payment-modal-title"
        aria-describedby="payment-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '0',
            boxShadow: 24,
            p: 4,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '15px',
          }}
        >
          <Box>
            <img
              src={HertexLogo}
              alt="Hertex Logo"
              style={{ width: '40px', height: '40px' }}
            />
          </Box>
          <Box>
            {total > Number(creditData?.available_credit) ? (
              <>
                <Typography>
                  Available Credit: R{Number(creditData?.available_credit).toFixed(2)}
                </Typography>

                <Typography color="error" sx={{ mt: 2 }}>
                  Your credit is insufficient for this transaction.
                </Typography>
              </>
            ) : (
              <Typography>
                Available Credit: R{Number(creditData?.available_credit).toFixed(2)}
              </Typography>
            )}
          </Box>
          <Box
            sx={{
              width: '100%',
            }}
          >
            <Button
              variant="contained"
              disableElevation
              sx={{
                backgroundColor: '#000',
                color: '#FFF',
                padding: '24px',
                width: '100%',
                textTransform: 'uppercase',
                px: '10px',
              }}
              disabled={isLoading || total > Number(creditData?.available_credit)}
              onClick={() => creditPayMutation.mutate()}
            >
              {isLoading ? (
                <Typography>Processing</Typography>
              ) : (
                <Typography>Pay Now</Typography>
              )}
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );

  const renderPayWithPayFastScreen = () => (
    <>
      <Button
        variant="contained"
        disableElevation
        sx={{
          px: '10px',
        }}
        onClick={handlePayment}
        disabled={loadingItemId !== null}
      >
        Continue to pay with payfast
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="payment-modal-title"
        aria-describedby="payment-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '0',
            boxShadow: 24,
            p: 4,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '15px',
          }}
        >
          <Box>
            <img
              src={HertexLogo}
              alt="Hertex Logo"
              style={{ width: '40px', height: '40px' }}
            />
          </Box>
          <Box sx={{ width: '100%' }} id="payment-container"></Box>
        </Box>
      </Modal>
    </>
  );

  return (
    <>
      {isPaymentTypeLoading ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
          }}
        >
          <CircularProgress />
        </Box>
      ) : canUseAccountPayment ? (
        <>
          <Typography
            sx={{
              fontSize: '18px',
              fontWeight: '400',
              lineHeight: '27px',
              my: 1,
            }}
          >
            Please Choose Payment Type
          </Typography>

          <FormControl
            sx={{
              width: '100%',
            }}
          >
            <RadioGroup
              aria-labelledby="payment-method-radio-group-label"
              defaultValue={paymentMethod}
              name="payment-method-radio-group"
              value={paymentMethod}
              onChange={handlePaymentMethodChange}
            >
              <FormControlLabel
                value="30E"
                control={<Radio />}
                label="Pay on account"
                sx={{
                  width: '100%',
                  border: '1px solid #D9D9D9',
                }}
              />
              <FormControlLabel
                value="PIA"
                control={<Radio />}
                label="Pay with PayFast"
                sx={{
                  width: '100%',
                  border: '1px solid #D9D9D9',
                }}
              />
            </RadioGroup>
          </FormControl>
        </>
      ) : (
        <>
          <Typography
            sx={{
              fontSize: '18px',
              fontWeight: '400',
              lineHeight: '27px',
              mb: 2,
            }}
          >
            Please Choose Payment Type
          </Typography>

          <FormControl>
            <RadioGroup
              aria-labelledby="payment-method-radio-group-label"
              defaultValue="PIA"
              name="payment-method-radio-group"
              value={paymentMethod}
              onChange={handlePaymentMethodChange}
            >
              <FormControlLabel
                value="PIA"
                control={<Radio />}
                label="Pay with PayFast"
              />
            </RadioGroup>
          </FormControl>
        </>
      )}

      <Divider sx={{ mt: 2 }} />
      <Grid container mt={2} justifyContent="space-between">
        <Box
          onClick={previousStep}
          sx={{
            display: 'flex',
            alignItems: 'center',
            borderRadius: '0px',
            cursor: 'pointer',
          }}
        >
          <Typography>Back</Typography>
        </Box>

        {paymentMethod === 'PIA'
          ? renderPayWithPayFastScreen()
          : renderPayWithCreditScreen()}
      </Grid>
    </>
  );
}

export default EditDetails;