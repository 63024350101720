import {
  createContext,
  ReactNode,
  useContext,
  useState,
  useEffect,
  useMemo,
} from 'react';
import Cart from '../components/shared/shoppingCart/Cart';
import CartService from '../service/CartService';
import { useQuery, useQueryClient } from 'react-query';
import { useAuth } from './AuthContext';
import { discount, getStockForItem } from '../api/apiCart';

// Define type for stock adjustment result
type StockValidationResult = {
  stockChanged: boolean;
  adjustedItems: Array<{
    name: string;
    oldQuantity: number;
    newQuantity: number;
    status?: 'adjusted' | 'removed';
  }>;
};

type ShoppingCartContextType = {
  setFormSteps: any;
  formSteps: number;
  setShippingSelected: any;
  shippingSelected: any;
  setDefaultShipSelected: any;
  defaultShipSelected: any;
  openCart: () => void;
  closeCart: () => void;
  setCartItemList: any;
  addToCart: (item: object) => void;
  removeFromCart: (item: object) => void;
  increaseQuantity: (item: object) => void;
  decreaseQuantity: (item: object) => void;
  addShippingPrice: (price: number) => void;
  processPaymentWithAccount: (cartId: string) => Promise<any>;
  processPaymentWithPayfast: (cartId: string) => Promise<any>;
  checkPaymentStatus: (cartId: string) => Promise<any>;
  clearCart: () => void;
  cartItemList: Array<object>;
  itemTotal: number;
  total: number;
  vatAmount: number;
  shippingPrice: number;
  cartService: CartService;
  setStockLink: (stock_link: string) => void;
  updateQuantity: (cartId: string, newQuantity: number) => void;
  discountAmount: any;
  discountAmount_total: number;
  discountAmount_type: string;
  loadingItemId: string | null;
  loadingDeleteItemId: string | null;
  billingAddress: any;
  cartId: string;
  cartItemData: any;
  cartAddressDetails: any;
  validateCartStock: () => Promise<StockValidationResult | null>; // Updated return type
  updateDeliveryInfo: (
    billingAddress: any,
    deliveryType: 'COLLECTION' | 'DELIVERY' | 'THIRD_PARTY_DELIVERY',
    customerOrderNum?: string,
    deliveryNotes?: string,
    contactPerson?: {
      name: string;
      surname: string;
      phone: string;
    },
    paymentType?: '30E' | 'PIA'
  ) => void;
  subTotal: number;
};

type ShoppingCartProviderProps = {
  children: ReactNode;
};

const ShoppingCartContext = createContext({} as ShoppingCartContextType);

export const ShoppingCartProvider = ({
                                       children,
                                     }: ShoppingCartProviderProps) => {
  const [formSteps, setFormSteps] = useState<any>();

  const [loadingItemId, setLoadingItemId] = useState<string | null>(null);
  const [loadingDeleteItemId, setLoadingDeleteItemId] = useState<string | null>(
    null
  );
  const [billingAddress, setBillingAddress] = useState<any>(null);
  const [cartId, setCartId] = useState<string>('');
  const [cartAddressDetails, setCartAddressDetails] = useState<any>({});

  const [cartItemList, setCartItemList] = useState<any>([]);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [itemTotal, setItemTotal] = useState<number>(0);

  const [total, setTotal] = useState<number>(0);
  const [subTotal, setSubTotal] = useState<number>(0);

  const [vatAmount, setVatAmount] = useState<number>(0);

  const [shippingPrice, setShippingPrice] = useState<number>(0);

  const [shippingSelected, setShippingSelected] = useState<boolean>();

  const [defaultShipSelected, setDefaultShipSelected] = useState<boolean>();
  const [stockLink, setStockLink] = useState<string>('');

  const [isValidatingStock, setIsValidatingStock] = useState<boolean>(false);
  const { isLogin, setIsAccountOnHold } = useAuth();

  const authToken = localStorage.getItem('authToken') || '';
  const cartService = useMemo(
    () => new CartService(authToken),
    [authToken]
  );

  const queryClient = useQueryClient();

  const { data: cartItemData } = useQuery(
    ['cart'],
    () => cartService.checkAndRetrieveCart(),
    {
      initialData: [],
      enabled: !!authToken && !!isLogin,
      onSuccess: (data) => {
        if (data?.on_hold === 'true') {
          setIsAccountOnHold(true);
        }
        setCartId(data?.id);

        // Check if this is a new cart and if the saved shipping address was for collection
        const isNewCart = !data?.items || data.items.length === 0;
        const savedAddressIsCollection =
          data?.context?.saved_shipping_address?.delivery_type === "COLLECTION";

        // Enhanced billing address extraction
        // First try to get billing_address directly, or build from orders_shipping_address
        const billingData = data?.billing_address || {};

        // If we don't have complete billing address info, try to extract from orders_shipping_address
        if (data?.orders_shipping_address && !(isNewCart && savedAddressIsCollection)) {
          const shippingAddr = data.orders_shipping_address;

          // Comprehensive billing address object
          const extractedBillingAddress = {
            email: data?.customer_email || '',
            name: shippingAddr?.contact_person?.name || '',
            surname: shippingAddr?.contact_person?.surname || '',
            address: shippingAddr?.address_line_1 || '',
            apartment: shippingAddr?.address_line_2 || '',
            suburb: shippingAddr?.address_line_3 || '',
            city: shippingAddr?.address_line_4 || '',
            province: (shippingAddr?.address_line_5 || '').split(',')[0]?.trim() || '',
            country: (shippingAddr?.address_line_5 || '').split(',')[1]?.trim() || 'South Africa',
            postalCode: shippingAddr?.address_zip || '',
            contact_number: data?.contact_number || '',
          };

          // Merge with any existing billing data
          setBillingAddress({...extractedBillingAddress, ...billingData});

          // Format address details for components that expect a different structure
          const formattedAddress = {
            physical1: shippingAddr.address_line_1 || '',
            physical2: shippingAddr.address_line_2 || '',
            physical3: shippingAddr.address_line_3 || '',
            physical4: shippingAddr.address_line_4 || '',
            physical5: shippingAddr.address_line_5 || '',
            physicalPC: shippingAddr.address_zip || '',
            contact_number: data.contact_number || '',
            contact_person: shippingAddr.contact_person || {},
          };
          setCartAddressDetails(formattedAddress);
        } else {
          setBillingAddress(billingData);
          setCartAddressDetails({});
        }
      },
    }
  );

  const { data: discountAmount } = useQuery(
    ['discount', stockLink],
    () => discount(authToken, Number(stockLink)),
    {
      enabled: !!authToken && !!stockLink,
    }
  );

  const [discountAmountTotal, setDiscountAmountTotal] = useState<number>(0);
  const [discountAmountType, setDiscountAmountType] = useState<string>('');

  useEffect(() => {
    if (discountAmount) {
      setDiscountAmountTotal(discountAmount?.discount_total || 0);
      setDiscountAmountType(discountAmount?.discount_type || '');
    }
  }, [discountAmount]);

  const openCart = () => {
    setIsOpen(true);
  };

  const closeCart = () => {
    setIsOpen(false);
  };


  const validateCartStock = async (): Promise<StockValidationResult | null> => {
    if (!cartItemList || cartItemList.length === 0 || !cartId) {
      return null;
    }

    try {
      const adjustedItems = [];
      let stockChanged = false;

      // Loop through cart items and check stock for each
      for (const item of cartItemList) {
        // Get fresh stock data
        const stockResponse = await cartService.checkItemStock(item.sku);

        if (stockResponse && stockResponse.success) {
          // Get the stock value from the response
          const availableStock = stockResponse.stock_on_hand || 0;
          const currentItemQty = item.quantity || 0;

          console.log(`Stock validation for ${item.name}:`, {
            currentQty: currentItemQty,
            availableStock: availableStock
          });

          // Compare cart quantity directly with available stock
          if (currentItemQty > availableStock) {
            stockChanged = true;

            if (availableStock <= 0) {
              adjustedItems.push({
                name: item.name,
                oldQuantity: currentItemQty,
                newQuantity: 0,
                status: 'removed' as 'removed'
              });

              await removeFromCart(item);
            } else {
              adjustedItems.push({
                name: item.name,
                oldQuantity: currentItemQty,
                newQuantity: availableStock,
                status: 'adjusted' as 'adjusted'
              });

              await updateQuantity(item.id, availableStock);
            }
          }
        }
      }

      if (stockChanged) {
        queryClient.invalidateQueries('cart');
      }

      return { stockChanged, adjustedItems };
    } catch (error) {
      console.error('Error validating cart stock:', error);
      return null;
    }
  };






  const addToCart = async (cartItem: any) => {
    console.log("ADD TO CART - Starting with item:", cartItem.sku, "Stock before:", cartItem.stock);
    setLoadingItemId(cartItem.id);
    try {
      // If item exists in cart, calculate new quantity
      const existingCartItem = cartItemList.find(
        (item: any) => item.sku === cartItem.sku
      );

      const quantity = existingCartItem
        ? Math.min(existingCartItem.quantity + cartItem.quantity, cartItem.stock)
        : cartItem.quantity;

      console.log("ADD TO CART - Calculated quantity:", quantity);

      // Use the new endpoint to set item quantity
      const response = await cartService.setItemAmount(
        cartItemData.id,
        cartItem.sku,
        quantity
      );

      console.log("ADD TO CART - API Response:", response);

      // Refresh cart data
      queryClient.invalidateQueries('cart');
      setIsOpen(true);
    } catch (error) {
      console.error('Error adding item to cart:', error);
    } finally {
      setLoadingItemId(null);
    }
  };


  const removeFromCart = async (cartItem: any) => {
    setLoadingDeleteItemId(cartItem.id);
    try {
      // Use the SKU instead of ID
      await cartService.deleteItemFromCart(cartItemData.id, cartItem.sku);

      // Immediately update the local state to reflect the removal
      setCartItemList((prevItems: any[]) =>
        prevItems.filter((item: any) => item.id !== cartItem.id)
      );

      // Also refresh cart data from the server
      await queryClient.refetchQueries('cart');
    } catch (error) {
      console.error('Error removing item from cart:', error);
    } finally {
      setLoadingDeleteItemId(null);
    }
  };

  const clearCart = async () => {
    try {
      await cartService.clearCartContents(cartItemData.id);

      // Refresh cart data
      queryClient.invalidateQueries('cart');
      setCartItemList([]);
    } catch (error) {
      console.error('Error clearing cart:', error);
    }
  };


  const updateQuantity = async (cartItemId: string, newQuantity: number) => {
    setLoadingItemId(cartItemId);
    try {
      // Find the item in cartItemList to get its SKU
      const cartItem = cartItemList.find((item: any) => item.id === cartItemId);
      if (!cartItem) {
        console.error('Item not found in cart');
        return;
      }

      // Use the SKU, not the ID
      await cartService.setItemAmount(
        cartItemData.id,
        cartItem.sku, // Use SKU here, not cartItemId
        newQuantity
      );

      // Refresh cart data
      queryClient.invalidateQueries('cart');
    } catch (error) {
      console.error('Error updating quantity:', error);
    } finally {
      setLoadingItemId(null);
    }
  };

  const increaseQuantity = async (cartItem: any) => {
    setLoadingItemId(cartItem.id);
    try {
      // Send the request to increase by 1
      const response = await cartService.editItemAmount(
        cartItemData.id,
        cartItem.sku,
        1
      );

      console.log("Increase quantity response:", response);

      // If the server response is successful, immediately update all relevant states
      if (response && response.success) {
        // Find the updated item in the cart response
        const updatedItem = response.cart?.items?.find((i: any) => i.sku === cartItem.sku);

        if (updatedItem) {
          // Immediately update this item in the cart list with all relevant properties
          setCartItemList((prevItems: any[]) =>
            prevItems.map((item: any) =>
              item.sku === cartItem.sku
                ? {
                  ...item,
                  quantity: updatedItem.quantity,
                  unit_price_excl: updatedItem.unit_price_excl,
                  unit_price_incl: updatedItem.unit_price_incl,
                  item_total_excl: updatedItem.item_total_excl,
                  item_total_incl: updatedItem.item_total_incl,
                  stock: response.stock_on_hand !== undefined ? response.stock_on_hand : item.stock,
                }
                : item
            )
          );

          // Also update the cart totals immediately from the response
          if (response.cart) {
            setSubTotal(response.cart.subtotal || 0);
            setVatAmount(response.cart.tax_total || 0);
            setTotal(response.cart.total || 0);
          }
        }
      } else {
        console.error("Failed to increase quantity:", response?.msg);

        // Even on failure, update local stock information if provided
        if (response && response.stock_on_hand !== undefined) {
          setCartItemList((prevItems: any[]) =>
            prevItems.map((item: any) =>
              item.sku === cartItem.sku
                ? {
                  ...item,
                  stock: response.stock_on_hand,
                  stock_on_hand: response.stock_on_hand,
                  total_available: response.total_available
                }
                : item
            )
          );
        }
      }

      // Still do a query invalidation to ensure backend sync
      queryClient.invalidateQueries('cart');
    } catch (error) {
      console.error('Error increasing quantity:', error);
      queryClient.invalidateQueries('cart');
    } finally {
      setLoadingItemId(null);
    }
  };


  const decreaseQuantity = async (cartItem: any) => {
    setLoadingItemId(cartItem.id);
    try {
      if (cartItem.quantity > 1) {
        // Use the new endpoint to decrease quantity by 1
        await cartService.editItemAmount(
          cartItemData.id,
          cartItem.sku, // Should be the SKU
          -1
        );

        // Refresh cart data
        queryClient.invalidateQueries('cart');
      } else {
        // If quantity is 1, remove the item
        removeFromCart(cartItem);
      }
    } catch (error) {
      console.error('Error decreasing quantity:', error);
    } finally {
      setLoadingItemId(null);
    }
  };

  const addShippingPrice = (price: number) => {
    setShippingPrice(price);
  };

  const updateDeliveryInfo = async (
    billingAddress: any,
    deliveryType: 'COLLECTION' | 'DELIVERY' | 'THIRD_PARTY_DELIVERY',
    customerOrderNum?: string,
    deliveryNotes?: string,
    contactPerson?: {
      name: string;
      surname: string;
      phone: string;
    },
    paymentType: '30E' | 'PIA' = 'PIA'
  ) => {
    try {
      // Format the address from the frontend format to the API format
      const shippingAddress = {
        address_line_1: billingAddress.address || '',
        address_line_2: billingAddress.apartment || '',
        address_line_3: billingAddress.suburb || '',
        address_line_4: billingAddress.city || '',
        address_line_5: `${billingAddress.province || ''}, ${billingAddress.country || ''}`,
        address_zip: billingAddress.postalCode || '',
      };

      const deliveryInfo = {
        shipping_address: shippingAddress,
        delivery_type: deliveryType,
        delivery_note: deliveryNotes || '',
        customer_order_num: customerOrderNum || '',
        contact_person: contactPerson || {
          name: billingAddress.name || '',
          surname: billingAddress.surname || '',
          phone: billingAddress.contact_number || '',
        },
        payment_type: paymentType,
      };

      await cartService.updateDeliveryInfo(cartItemData.id, deliveryInfo);
      setBillingAddress(billingAddress);
      queryClient.invalidateQueries('cart');
    } catch (error) {
      console.error('Error updating delivery information:', error);
    }
  };

  const processPaymentWithAccount = async (cartId: string) => {
    try {
      return await cartService.finalizeUserCartWithAccount(cartId);
    } catch (error) {
      console.error('Error processing account payment:', error);
      throw error;
    }
  };

  const processPaymentWithPayfast = async (cartId: string) => {
    try {
      return await cartService.finalizeUserCartWithPayfast(cartId);
    } catch (error) {
      console.error('Error processing PayFast payment:', error);
      throw error;
    }
  };

  const checkPaymentStatus = async (cartId: string) => {
    try {
      return await cartService.getCartPaymentStatus(cartId);
    } catch (error) {
      console.error('Error checking payment status:', error);
      throw error;
    }
  };

  useEffect(() => {
    if (
      cartItemData &&
      Array.isArray(cartItemData.items) &&
      cartItemData.items.length > 0
    ) {
      // Get the latest stock information before setting cart items
      const fetchStockInfo = async () => {
        // Create a copy of the items to modify
        const itemsWithStock = cartItemData.items.map((item: any) => ({
          ...item,
          // Use the stock_on_hand property if it exists
          stock: item.stock_on_hand,
          on_hand: item.stock_on_hand
        }));

        // Set cartItemList with the enhanced items
        setCartItemList(itemsWithStock);
      };

      fetchStockInfo();
    } else {
      setCartItemList([]);
    }
  }, [cartItemData]);

  useEffect(() => {
    if (cartItemData) {
      // Swap subtotal and VAT to match UI expectations
      setSubTotal(cartItemData.subtotal || 0);
      setVatAmount(cartItemData.tax_total || 0);
      setTotal(cartItemData.total || 0);
    }
  }, [cartItemData]);

  return (
    <ShoppingCartContext.Provider
      value={{
        setFormSteps,
        setShippingSelected,
        setCartItemList,
        openCart,
        closeCart,
        addToCart,
        removeFromCart,
        increaseQuantity,
        decreaseQuantity,
        addShippingPrice,
        updateDeliveryInfo,
        clearCart,
        processPaymentWithAccount,
        processPaymentWithPayfast,
        checkPaymentStatus,
        formSteps,
        shippingSelected,
        cartItemList,
        itemTotal,
        total,
        vatAmount,
        shippingPrice,
        cartService,
        setDefaultShipSelected,
        defaultShipSelected,
        updateQuantity,
        setStockLink,
        discountAmount,
        discountAmount_total: discountAmountTotal,
        discountAmount_type: discountAmountType,
        loadingItemId,
        loadingDeleteItemId,
        billingAddress,
        cartItemData,
        cartAddressDetails,
        cartId,
        subTotal,
        validateCartStock, // Add the new function to the context
      }}
    >
      {children}
      <Cart isOpen={isOpen} />
    </ShoppingCartContext.Provider>
  );
};

export const useShoppingCart = () => {
  return useContext(ShoppingCartContext);
};