import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Typography,
} from '@mui/material';
import { ChangeEvent } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// Update the interface to specify the correct event type
interface ApplicationSelectionProps {
  availableApplications: string[];
  handleCheckboxChange: (
    elem: string | number,
    setList: React.Dispatch<React.SetStateAction<string[]>>,
    list: string[],
    paramKey: string
  ) => (event: ChangeEvent<HTMLInputElement>) => void;
  applicationsList: string[];
  setApplicationsList: React.Dispatch<React.SetStateAction<string[]>>;
  itemGroup: string;
  applyFiltersForApplications: (applications: string[]) => void;
}

const ApplicationSelection = ({
                                availableApplications,
                                applicationsList,
                                setApplicationsList,
                                itemGroup,
                                applyFiltersForApplications
                              }: ApplicationSelectionProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  // Create a dedicated handler for application checkbox changes
  const handleApplicationChange = (applicationName: string) => (event: ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;

    // Create a new list of applications based on the checkbox state and special combinations
    let updatedList: string[] = [...applicationsList];

    if (isChecked) {
      // Handle special combinations for item group 5
      if (itemGroup === '005') {
        if (applicationName === 'Curtaining') {
          // When Curtaining is selected, add both Curtaining and Dual Purpose
          updatedList = updatedList.filter(app => app !== 'Upholstery'); // Remove Upholstery if present
          if (!updatedList.includes('Curtaining')) updatedList.push('Curtaining');
          if (!updatedList.includes('Dual Purpose')) updatedList.push('Dual Purpose');
        }
        else if (applicationName === 'Upholstery') {
          // When Upholstery is selected, add both Upholstery and Dual Purpose
          updatedList = updatedList.filter(app => app !== 'Curtaining'); // Remove Curtaining if present
          if (!updatedList.includes('Upholstery')) updatedList.push('Upholstery');
          if (!updatedList.includes('Dual Purpose')) updatedList.push('Dual Purpose');
        }
        else if (applicationName === 'Dual Purpose') {
          // When Dual Purpose is selected alone, only add Dual Purpose
          updatedList = ['Dual Purpose'];
        }
      } else {
        // For other item groups, just add the selected application
        if (!updatedList.includes(applicationName)) {
          updatedList.push(applicationName);
        }
      }
    } else {
      // Handle unchecking logic for item group 5
      if (itemGroup === '005') {
        if (applicationName === 'Curtaining') {
          // If unchecking Curtaining, remove both Curtaining and potentially Dual Purpose
          updatedList = updatedList.filter(app => app !== 'Curtaining');
          // Only remove Dual Purpose if Upholstery isn't selected
          if (!updatedList.includes('Upholstery')) {
            updatedList = updatedList.filter(app => app !== 'Dual Purpose');
          }
        }
        else if (applicationName === 'Upholstery') {
          // If unchecking Upholstery, remove both Upholstery and potentially Dual Purpose
          updatedList = updatedList.filter(app => app !== 'Upholstery');
          // Only remove Dual Purpose if Curtaining isn't selected
          if (!updatedList.includes('Curtaining')) {
            updatedList = updatedList.filter(app => app !== 'Dual Purpose');
          }
        }
        else if (applicationName === 'Dual Purpose') {
          // When unchecking Dual Purpose, clear all selections
          updatedList = [];

          // Immediately update state, URL, and apply filters before returning
          setApplicationsList(updatedList);

          // Update URL parameters
          const dualPurposeParams = new URLSearchParams(location.search);
          Array.from(dualPurposeParams.entries()).forEach(([key, _]) => {
            if (key === 'application') {
              dualPurposeParams.delete(key);
            }
          });

          // Reset pagination
          dualPurposeParams.set('page', '1');
          dualPurposeParams.set('startoffset', '0');
          dualPurposeParams.set('endoffset', '9');

          // We need to handle the URL path differently - extract the base path without the application part
          const pathParts = location.pathname.split('/');
          const applicationIndex = pathParts.indexOf('application');

          let newPath = location.pathname;

          // If we have an application in the path, remove it
          if (applicationIndex !== -1 && applicationIndex + 1 < pathParts.length) {
            // Remove the 'application' and its value from the path
            pathParts.splice(applicationIndex, 2);
            newPath = pathParts.join('/');
          }

          // Update URL without causing a rerender
          if (typeof window !== "undefined") {
            const newUrl = `${newPath}?${dualPurposeParams.toString()}`;
            window.history.replaceState({ path: newUrl }, '', newUrl);
          }

          // Apply filters with empty application list
          applyFiltersForApplications([]);

          // Return early to prevent the code below from executing
          return;
        }
      } else {
        // For other item groups, just remove the application
        updatedList = updatedList.filter(app => app !== applicationName);
      }
    }

    // Update the application list state
    setApplicationsList(updatedList);

    // Update the URL parameters
    const currentParams = new URLSearchParams(location.search);

    // Remove all existing application parameters
    const paramEntries = Array.from(currentParams.entries());
    paramEntries.forEach(([key, _]) => {
      if (key === 'application') {
        currentParams.delete(key);
      }
    });

    // Add the updated applications to the URL
    updatedList.forEach(app => currentParams.append('application', app));

    // Reset pagination parameters
    currentParams.set('page', '1');
    currentParams.set('startoffset', '0');
    currentParams.set('endoffset', '9'); // Assuming itemsPerPage is 9

    // Handle the URL path based on the selected applications
    let newPath = location.pathname;
    const pathParts = location.pathname.split('/');
    const applicationIndex = pathParts.indexOf('application');

    // If we have applications selected
    if (updatedList.length > 0) {
      // If we already have an application in the path, update it
      if (applicationIndex !== -1 && applicationIndex + 1 < pathParts.length) {
        // Determine the primary application to show in the path
        let primaryApp = updatedList[0].toLowerCase();
        if (updatedList.includes('Curtaining')) {
          primaryApp = 'curtaining';
        } else if (updatedList.includes('Upholstery')) {
          primaryApp = 'upholstery';
        } else if (updatedList.includes('Dual Purpose')) {
          primaryApp = 'dual purpose';
        }

        // Update the application value in the path
        pathParts[applicationIndex + 1] = encodeURIComponent(primaryApp);
        newPath = pathParts.join('/');
      }
      // If we don't have an application in the path but should, add it
      else if (applicationIndex === -1) {
        // Determine the primary application to show in the path
        let primaryApp = updatedList[0].toLowerCase();
        if (updatedList.includes('Curtaining')) {
          primaryApp = 'curtaining';
        } else if (updatedList.includes('Upholstery')) {
          primaryApp = 'upholstery';
        } else if (updatedList.includes('Dual Purpose')) {
          primaryApp = 'dual purpose';
        }

        // Add application to the path
        pathParts.push('application', encodeURIComponent(primaryApp));
        newPath = pathParts.join('/');
      }
    }
    // If we have no applications selected, remove the application part from the path
    else if (applicationIndex !== -1 && applicationIndex + 1 < pathParts.length) {
      // Remove the 'application' and its value from the path
      pathParts.splice(applicationIndex, 2);
      newPath = pathParts.join('/');
    }

    // Update the URL without causing a rerender
    if (typeof window !== "undefined") {
      const newUrl = `${newPath}?${currentParams.toString()}`;
      window.history.replaceState({ path: newUrl }, '', newUrl);
    }

    // Apply the filters immediately
    applyFiltersForApplications(updatedList);
  };

  return (
    <Box>
      <Divider sx={{ backgroundColor: '#000000', mb: '1rem' }} />
      <Typography fontWeight='bold' fontSize='20px' mb={2}>
        Applications
      </Typography>

      <Box sx={{ overflowY: 'auto', maxHeight: '20.5rem' }}>
        <FormGroup>
          {availableApplications
            ?.sort()
            .map((elem: string) => (
              <FormControlLabel
                key={elem}
                control={
                  <Checkbox
                    checked={applicationsList.includes(elem)}
                    onChange={handleApplicationChange(elem)}
                  />
                }
                label={elem}
              />
            ))}
        </FormGroup>
      </Box>
    </Box>
  );
};

export default ApplicationSelection;