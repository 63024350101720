import { Box, Grid, Typography } from '@mui/material';

type ResourceImageGridProps = {
  header: string;
  data: { collection_name?: string; filename?: string; url: string }[];
};

const ResourcesImagesGrid = ({ header, data }: ResourceImageGridProps) => {
  return (
    <Box
      sx={{
        py: { xs: '20px', lg: '50px' },
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'start',
      }}
    >
      <Typography
        fontWeight='600'
        lineHeight={'24px'}
        fontSize={'18px'}
        mb={'20px'}
      >
        {header}
      </Typography>

      <Grid container spacing={2}>
        {data?.map((elem, index) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={2}
            key={`${elem.filename || elem.collection_name || ''}-${index}`}
          >
            <Box
              sx={{
                width: {
                  xs: '100%',
                  md: '100%',
                  height: '100%',
                  cursor: 'pointer',
                },
              }}
              onClick={() => {
                window.open(elem?.url, '_blank');
              }}
            >
              <img
                src={elem?.url}
                alt={`${elem.collection_name || 'Catalogue'} ${index + 1}`}
                style={{ width: '100%', height: '100%' }}
              />
            </Box>
            {elem?.collection_name && (
              <Typography
                fontWeight={'600'}
                textTransform={'capitalize'}
              >
                {elem.collection_name}
              </Typography>
            )}
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ResourcesImagesGrid;