import { useQuery } from "react-query";
import { Typography, CircularProgress, Box } from "@mui/material";
import TableInstance from "./TableInstance";
import { apiGetOrders } from "../../../../api/apiOrders";
//
function TableQuery() {
  const authToken = localStorage.getItem("authToken");
  const {
    isError,
    isLoading,
    data: openOrdersData,
  } = useQuery<any, Error>([`open-orders`], () =>
    apiGetOrders(authToken, false)
  );

  return (
    <>
      {isError && <Typography>An error occured</Typography>}
      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "90vh",
          }}
        >
          <CircularProgress />
        </Box>
      ) : openOrdersData?.orders?.length === 0 ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "80vh",
          }}
        >
          {" "}
          <Typography fontWeight={"bold"} fontSize={"1.5rem"}>
            You have no open orders
          </Typography>
        </Box>
      ) : (
        <TableInstance tableData={openOrdersData?.orders} />
      )}
    </>
  );
}
export default TableQuery;
