import {
    Box,
    Grid,
    Typography,
    Divider,
} from '@mui/material';

import Products from "../../../../../assets/products_svg.svg"

const ProductDetails = ({ productDetailsList }: any) => {
    return (
        <>
            <Grid
                sx={{ width: '100%' }}
                container
                justifyContent='center'
                alignItems='center'
            >
            </Grid>
            <Grid container>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        flex: 3,
                        mt: 3,
                        pl: { xs: '0', lg: '50px' },
                        mb: '3em',
                    }}
                >
                    <Typography
                        mb={2}
                        variant='h5'
                        fontSize={'40px'}
                        fontWeight={'500'}
                        lineHeight={'48px'}
                    >
                        Product Details
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        {productDetailsList.map((elem: any, index: number) => (
                            <Box key={index}>
                                <Box
                                    sx={{
                                        display:
                                            elem?.value?.props?.children?.length === 0 ||
                                            elem.value === 'N/A' ||
                                            elem.value === '-' ||
                                            elem.value === ''
                                                ? 'none'
                                                : 'flex',
                                        justifyContent: 'flex-start',
                                        alignItems: 'center',
                                        gap: 2, // Add a controlled gap between columns
                                    }}
                                >
                                    <Box
                                        sx={{
                                            width: '25%', // Smaller fixed width for the first column
                                            flexShrink: 0,
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: '16px',
                                                fontWeight: '400',
                                            }}
                                            component="div"
                                        >
                                            {elem.key}
                                        </Typography>
                                    </Box>
                                    <Box
                                        sx={{
                                            flex: 1, // Second column expands to fill available space
                                            maxWidth: '60%',
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: '16px',
                                                fontWeight: '400',
                                            }}
                                            component="div"
                                        >
                                            {elem.value}
                                        </Typography>
                                    </Box>
                                </Box>
                                <Divider
                                    sx={{
                                        mt: 1,
                                        mb: 1,
                                        display:
                                            elem?.value?.props?.children?.length === 0 ||
                                            elem.value === 'N/A' ||
                                            elem.value === '-' ||
                                            elem.value === ''
                                                ? 'none'
                                                : 'flex',
                                    }}
                                    variant='fullWidth'
                                    color='black'
                                />
                            </Box>
                        ))}
                    </Box>

                    <Box
                        sx={{
                            display: "none",
                            flexDirection: "row",
                            gap: "10px",
                        }}
                    >
                        <img src={Products} alt="/" width={'100%'} />
                    </Box>
                </Box>
                <Box
                    sx={{
                        flex: 1,
                        display: {
                            xs: 'none',
                            sm: 'block',
                        },
                    }}
                ></Box>
            </Grid>
        </>
    );
};

export default ProductDetails;
