import { useMemo, useState } from "react";
import { useTable, usePagination, useGlobalFilter } from "react-table";
import TableLayout from "../TableLayout";
// import VisibilityIcon from '@mui/icons-material/Visibility';
import { Button, Typography } from "@mui/material";

function TableInstance({ tableData }: any) {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [itemId, setItemId] = useState<string>("");

  const [columns, data] = useMemo(() => {
    const columns = [
      {
        Header: <Typography variant="caption">Order Number</Typography>,
        accessor: "items[0].order_number",
        // @ts-ignore

        Cell: ({ value }) => (
          <Typography
            sx={{ fontFamily: "Inter", fontSize: "14px", fontWeight: "400" }}
          >
            {value}
          </Typography>
        ),
      },
      {
        Header: <Typography variant="caption">Waybill</Typography>,
        accessor: "order.dt_waybill",
        // @ts-ignore
        Cell: ({ row: { original } }) => (
          <Typography
            sx={{ fontFamily: "Inter", fontSize: "14px", fontWeight: "400" }}
          >
            {original.order.dt_waybill === null
              ? "NA"
              :   <a
                href={`https://login.tritonexpress.co.za/triton/trackit?waybillNo=${original.order.dt_waybill}&collectionNo=&xrefNo=&pUser=PUBLIC&pLevel=P&myswitch=&BtnSubmit=Submit`}
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: 'none', color: 'inherit' }}
              >
                {original.order.dt_waybill}
              </a>}
          </Typography>
        ),
      },
      {
        Header: <Typography variant="caption">Status</Typography>,
        accessor: "order.order_status",
        // @ts-ignore

        Cell: ({ value }) => (
          <Button
            disableElevation

            sx={{
              fontSize: "10px",
              fontWeight: "500",
              textTransform: "capitalize",
              background: "#FFC452",
              borderRadius: "16px",
              color: "#AD4900",
              height: '10px',
              px: '10px',
            }}
          >
            {value}
          </Button>
        ),
      },

      {
        Header: " ",

        // @ts-ignore
        Cell: ({ row: { original } }) => (
          // <VisibilityIcon
          //     sx={{ cursor: 'pointer' }}
          //     onClick={() => {
          //         setOpenModal(true);
          //         setItemId(original.items[0].order_number);
          //     }}
          // />
          <Button
            sx={{
              cursor: "pointer",
              color: "#475467",
              fontSize: "14px",
              fontWeight: "400",
              textTransform: "none",
            }}
            onClick={() => {
              setOpenModal(true);
              setItemId(original.items[0].order_number);
            }}
          >
            View
          </Button>
        ),
      },
    ];
    return [columns, tableData];
  }, [tableData]);

  const tableInstance = useTable(
    { columns, data },
    useGlobalFilter,
    usePagination
  );

  return (
    <TableLayout
      setOpenModal={setOpenModal}
      openModal={openModal}
      orderListData={data}
      itemId={itemId}
      {...tableInstance}
    />
  );
}

export default TableInstance;
