// FilterSelection.tsx
import Filter from './Filter';
import { Box, Button, Divider, Typography, useMediaQuery } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useEffect, useState } from 'react';

interface FilterCategory {
    title: string;
    available: any[];
    filtered: any[];
    list: string[];
    setList: React.Dispatch<React.SetStateAction<string[]>>;
    keyName: string;
    paramKey: string;
}

const FilterSelection = ({
                             // Available options
                             availableColours,
                             availableDesigns,
                             availableTypes,
                             availableBrands,
                             availableMaterials,
                             availableStandards,
                             availableSizes,
                             availableShapes,

                             // Filtered options
                             filteredColours,
                             filteredDesigns,
                             filteredTypes,
                             filteredBrands,
                             filteredMaterials,
                             filteredStandards,
                             filteredSizes,
                             filteredShapes,

                             // Selected values
                             colourList,
                             designList,
                             typesList,
                             brandList,
                             materialList,
                             standardsList,
                             sizeList,
                             shapeList,

                             // State setters
                             setColourList,
                             setDesignList,
                             setTypesList,
                             setBrandList,
                             setMaterialList,
                             setStandardsList,
                             setSizeList,
                             setShapeList,

                             // Other props
                             handleCheckboxChange,
                             applyFilters,
                             applicationsList,
                             itemGroup,
                         }: any) => {
    // Initialize visibility state for all filter sections
  const [visibility, setVisibility] = useState<{ [key: string]: boolean }>({
    Colour: false,
    Design: false,
    Types: false,
    Brand: false,
    Material: false,
    Standards: false,
    Size: false,
    Shape: false,
  });

    const isMobile = useMediaQuery('(max-width:600px)');

    // Toggle all sections closed on mobile
    useEffect(() => {
        if (isMobile) {
            setVisibility(prevState =>
                Object.keys(prevState).reduce((acc, key) => ({
                    ...acc,
                    [key]: false
                }), {})
            );
        }
    }, [isMobile]);

    const filterCategories: FilterCategory[] = [
        {
            title: 'Colour',
            available: availableColours,
            filtered: filteredColours,
            list: colourList,
            setList: setColourList,
            keyName: 'colours',
            paramKey: 'colours',
        },
        {
            title: 'Design',
            available: availableDesigns,
            filtered: filteredDesigns,
            list: designList,
            setList: setDesignList,
            keyName: 'design_style',
            paramKey: 'design_style',
        },
        {
            title: 'Types',
            available: availableTypes,
            filtered: filteredTypes,
            list: typesList,
            setList: setTypesList,
            keyName: 'types',
            paramKey: 'types',
        },
        {
            title: 'Brand',
            available: availableBrands,
            filtered: filteredBrands,
            list: brandList,
            setList: setBrandList,
            keyName: 'brand',
            paramKey: 'brand',
        },
        {
            title: 'Material',
            available: availableMaterials,
            filtered: filteredMaterials,
            list: materialList,
            setList: setMaterialList,
            keyName: 'material',
            paramKey: 'material',
        },
        {
            title: 'Standards',
            available: availableStandards,
            filtered: filteredStandards,
            list: standardsList,
            setList: setStandardsList,
            keyName: 'standards',
            paramKey: 'standards',
        },
        {
            title: 'Size',
            available: availableSizes,
            filtered: filteredSizes,
            list: sizeList,
            setList: setSizeList,
            keyName: '',
            paramKey: 'sizes',
        },
        {
            title: 'Shape',
            available: availableShapes,
            filtered: filteredShapes,
            list: shapeList,
            setList: setShapeList,
            keyName: '',
            paramKey: 'shapes',
        },
    ];

    const getFilteredOptions = (available: any[], filtered: any[], category: string) => {
        // If no application selected or no available items, return available array
        if (!applicationsList?.length || !available?.length) {
            return available;
        }

        // If filtered is empty or undefined but we have available items, show all
        if (!filtered?.length && available?.length) {
            // For Types category, filter out the "-" value
            if (category === 'Types') {
                return available.filter(item => item.types !== '-');
            }
            return available;
        }

        // Handle string-based categories (Size and Shape)
        if (category === 'Size' || category === 'Shape') {
            const lowerFiltered = filtered.map((sz: string) => sz.toLowerCase());
            return available.filter((item) => lowerFiltered.includes(item.toLowerCase()));
        }

        // Handle object-based categories (everything else)
        return available.filter((item) => {
            // For Types category, filter out the "-" value before id comparison
            if (category === 'Types' && item.types === '-') {
                return false;
            }
            const itemId = String(item.idx);
            return filtered.some((f: { id: number }) => String(f.id) === itemId);
        });
    };

    return (
        <>
            {filterCategories.map((category) => {
                const filteredOptions = getFilteredOptions(
                    category.available,
                    category.filtered,
                    category.title
                );

                // Skip rendering if no options or shape for non-004 itemGroup
                if (!filteredOptions?.length ||
                    (category.title === 'Shape' && itemGroup !== '004')) {
                    return null;
                }

                return (
                    <Box key={category.title}>
                        <Divider sx={{ backgroundColor: '#000000', m: '1rem 0' }} />
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Typography fontWeight="bold" fontSize="20px" mb={2}>
                                {category.title}
                            </Typography>
                            <Box
                                onClick={() => setVisibility(prev => ({
                                    ...prev,
                                    [category.title]: !prev[category.title]
                                }))}
                                sx={{ cursor: 'pointer' }}
                            >
                                {visibility[category.title] ?
                                    <KeyboardArrowDownIcon /> :
                                    <KeyboardArrowUpIcon />
                                }
                            </Box>
                        </Box>
                        {visibility[category.title] && (
                            <Filter
                                filterType={filteredOptions}
                                filteredOptions={category.filtered}
                                handleCheckboxChange={handleCheckboxChange}
                                list={category.list}
                                setList={category.setList}
                                keyName={category.keyName}
                                paramKey={category.paramKey}
                            />
                        )}
                    </Box>
                );
            })}

            <Divider sx={{ backgroundColor: '#000000', m: '1rem 0' }} />
            <Button
                variant="contained"
                onClick={applyFilters}
                sx={{ width: { xs: '100%', lg: '50%' } }}
                disableElevation
            >
                Apply
            </Button>
            <Divider sx={{ backgroundColor: '#000000', m: '1rem 0' }} />
        </>
    );
};

export default FilterSelection;