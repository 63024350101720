import PageLayout from '../layout/PageLayout';
import { useQuery } from 'react-query';
import { useParams, useLocation } from 'react-router-dom';
import {
  apiGetCollectionPageData,
  apiGetDesignsInCollection,
} from '../../../api/apiCollections';
import LoadingScreen from '../loadingScreen/LoadingScreen';
import TextHeader from '../textHeader/TextHeader';
import Gallery from '../gallery/Gallery';
import { useEffect, useState } from 'react';
import MissingPage from '../errorMessages/MissingPage';
import DesignsInCollectionGrid from '../grid/DesignsInCollectionGrid';
import CarouselBanner from '../banner/CarouselBanner';
import { captureAnalytics } from '../../../utilities/Analytics';
import FilterComponent from '../filter/FilterComponent';
import { apiGetFilteredPromoProducts, apiGetPromoLayout } from '../../../api/apiPromos';
import { CategorySettings, PromoProduct, PromoFilters } from '../pageTemplates/promo/types';

function CollectionTemplate() {
  const { item_group, application, collection } = useParams();
  const location = useLocation();

  // Existing states
  const [applicationsList, setApplicationsList] = useState<string[]>([]);
  const [colourList, setColourList] = useState<number[]>([]);
  const [designList, setDesignList] = useState<number[]>([]);
  const [typeList, setTypeList] = useState<number[]>([]);

  // Add new states for promo functionality
  const [promoProducts, setPromoProducts] = useState<PromoProduct[]>([]);
  const [categorySettings, setCategorySettings] = useState<CategorySettings>();

  const argumentDataStructure = {
    category: {
      application: application,
      item_group: item_group,
      collection: collection,
    },
  };

  const designFilters = {
    filter: {
      item_group: item_group,
      application: application,
      range: collection,
    },
    off_setter: {
      start: 0,
      end: 100,
    },
  };

  const {
    isSuccess,
    isError,
    isLoading,
    data: collectionData,
  } = useQuery<any, Error>([`collection-${collection}`], () =>
    apiGetCollectionPageData(argumentDataStructure)
  );

  const { data: designsData } = useQuery(
    [`collection-designs-${collection}`],
    () => apiGetDesignsInCollection(designFilters)
  );

  // Add new useEffect for fetching promo data with type safety
  useEffect(() => {
    const fetchPromoData = async () => {
      // Only proceed if item_group is defined
      if (!item_group) return;

      try {
        // Create empty filter request that matches PromoFilters
        const emptyFilter: PromoFilters = {
          application: [],
          colour: [],
          design: [],
          type: [],
          brand: [],
          material: [],
          standard: [],
          size: [],
          shape: []
        };

        const [promoResponse, layoutResponse] = await Promise.all([
          apiGetFilteredPromoProducts(item_group, emptyFilter, 0, 1000),
          apiGetPromoLayout(1)
        ]);

        if (promoResponse.success) {
          setPromoProducts(promoResponse.promo_products || []);
        }

        const matchingCategory = layoutResponse?.main_settings?.category_settings?.find(
          category => category.item_group === item_group
        );

        if (matchingCategory) {
          setCategorySettings(matchingCategory);
        }
      } catch (error) {
        console.error('Error fetching promo data:', error);
      }
    };

    fetchPromoData();
  }, [item_group]);

  // Existing URL parameter extraction
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    const applicationParams = searchParams.getAll('application');
    const colourParams = searchParams.getAll('colours');
    const designParams = searchParams.getAll('design_style');
    const typeParams = searchParams.getAll('types');

    setApplicationsList(applicationParams);
    setColourList(colourParams.map(Number));
    setDesignList(designParams.map(Number));
    setTypeList(typeParams.map(Number));
  }, [location.search]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    captureAnalytics(
      'pageview',
      location.pathname,
      `Collection - ${collection}`
    );
  }, [location.pathname, collection]);

  return (
    <PageLayout>
      {isError && <MissingPage />}
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <>
          {isSuccess && (
            <>
              <CarouselBanner
                heroImages={collectionData?.section_trade_hero}
                heroLink={collectionData?.section_trade_hero_link}
                heroVideo={collectionData?.section_trade_hero_video ? {
                  video_url: collectionData.section_trade_hero_video.video_url,
                  thumbnail_url: collectionData.section_trade_hero_video.thumbnail_url
                } : undefined}
                videoLink={collectionData?.section_trade_video_link}
                promoImages={collectionData?.section_trade_promo}
                promoLink={collectionData?.section_trade_promo_link}
                displayOption={collectionData?.trade_display_option}
              />
              <TextHeader
                heading={collectionData?.page_title}
                content={collectionData?.description}
                isCentered={false}
                width="70%"
                marginTop={10}
                marginBottom={5}
              />

              <Gallery
                images={collectionData?.section_gallery}
                galleryLayout={'masonry'}
                columns={2}
                title={'title'}
                secondaryText={'2bd'}
                hasTitle={false}
                hasSecondaryText={false}
              />

              <DesignsInCollectionGrid
                data={designsData}
                marginTop="5rem"
                marginBottom="5rem"
                promoProducts={promoProducts}
                categorySettings={categorySettings}
              />
            </>
          )}
        </>
      )}
    </PageLayout>
  );
}

export default CollectionTemplate;