import { useState, useEffect, useMemo } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
import PageLayout from '../../layout/PageLayout';
import { Grid, Container, Box } from '@mui/material';
import LoadingScreen from '../../loadingScreen/LoadingScreen';
import ImageSection from './components/ImageSection';
import ProductSection from './components/ProductSection';
import ProductDetails from './components/ProductDetails';
import MissingPage from '../../errorMessages/MissingPage';
import { apiFilterProducts } from '../../../../api/apiMultiFilter';
import { apiGetFilteredPromoProducts, apiGetPromoProductBySku } from '../../../../api/apiPromos';
import { captureAnalytics } from '../../../../utilities/Analytics';
import { apiGetMainSettings } from '../../../../api/apiPromos';

interface ProductPageProps {
  categorySettings?: {
    items_overlay_text?: string;
    items_overlay_text_colour?: string;
    items_overlay_text_size?: number;
  };
}

interface StockMaster {
  diameter?: string;
  width?: string;
  length_cm?: string;
  colour?: string;
  description_1?: string;
  design?: string;
  range?: string;
  brand?: string;
  composition?: string;
}

interface Product {
  id?: string;
  sku?: string;
  primaryImageUrl?: string;
  additionalImages?: any[];
  long_description?: string;
}

interface ProductItem {
  Product?: Product;
  StockMaster?: StockMaster;
}

function ProductPage({ categorySettings }: ProductPageProps) {
  // URL parameters
  const { item_group, application, collection, design, colour } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const promoId = searchParams.get('promoId');
  const targetSize = searchParams.get('targetSize');
  const targetProduct = searchParams.get('targetProduct');
  const isPromoProduct = !!promoId;

  // Get overlay parameters from URL
  const overlayText = searchParams.get('overlayText');
  const overlayColor = searchParams.get('overlayColor');
  const overlaySize = searchParams.get('overlaySize');

  // State variables
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [currentSelectedItem, setCurrentSelectedItem] = useState<ProductItem>();
  const [selectedImagePreviewIndex, setSelectedImagePreviewIndex] = useState<number>(0);
  const [width, setWidth] = useState('');
  const [lengthcm, setLengthCM] = useState('');
  const [diameter, setDiameter] = useState('');
  const [activeImageStep, setActiveImageStep] = useState(0);
  const [currentHasPromoPrice, setCurrentHasPromoPrice] = useState<boolean>(false);
  const [promosEnabled, setPromosEnabled] = useState<boolean>(true);
  const [isInitialLoad, setIsInitialLoad] = useState(true)

  // Main settings query with promo enable/disable logic
  const { data: mainSettings, isError: mainSettingsError } = useQuery(
    ['main-settings'],
    () => apiGetMainSettings(1),
    {
      staleTime: 300000,
      retry: 3, // Add retry attempts
      retryDelay: 1000, // Wait 1 second between retries
      onSuccess: (data) => {
        const isLive = data?.main_settings?.is_live === 1;
        setPromosEnabled(isLive);

      },
      onError: (error) => {
        console.error('Main settings error:', error);
        setPromosEnabled(true);
      }
    }
  );

  // Promo product query - only runs if promos are enabled
  const {
    data: promoData,
    isLoading: isLoadingPromo,
    isError: isErrorPromo
  } = useQuery(
    ['promo-product', targetProduct, promoId], // Add promoId to query key
    async () => {
      if (!targetProduct) throw new Error('Product SKU is required');
      if (!promosEnabled) return null;

      const response = await apiGetPromoProductBySku(targetProduct);
      if (response?.success && response.promo_product) {
        return {
          id: response.promo_product.id,
          product_sku: response.promo_product.product_sku,
          original_price_excl: response.promo_product.original_price_excl,
          promo_price_excl: response.promo_product.promo_price_excl,
          original_price_incl: response.promo_product.original_price_incl,
          promo_price_incl: response.promo_product.promo_price_incl
        };
      }
      return null;
    },
    {
      enabled: !!targetProduct && !!promoId && promosEnabled, // Simplified condition
      staleTime: 300000,
      retry: false
    }
  );

  // Design promo products query - only runs if promos are enabled
  const {
    data: designPromoProducts,
    isLoading: isLoadingDesignPromos
  } = useQuery(
    ['design-promos', item_group, application, design],
    async () => {
      if (!item_group || !application || !design) return null;
      if (!promosEnabled) return null;

      const response = await apiGetFilteredPromoProducts(
        item_group,
        {
          application: [application],
          design: [design],
          colour: [],
          type: [],
          brand: [],
          material: [],
          standard: [],
          size: [],
          shape: []
        },
        0,
        100
      );

      if (!response?.success) return null;
      return response.promo_products;
    },
    {
      enabled: !!item_group && !!application && !!design && !isPromoProduct && promosEnabled,
      staleTime: 300000
    }
  );

  const promoMap = useMemo(() => {
    if (!designPromoProducts || !promosEnabled) return new Map();
    return new Map(
      designPromoProducts.map(promo => [promo.product_sku, promo])
    );
  }, [designPromoProducts, promosEnabled]);

  // Combined overlay settings with promo disable logic
  const combinedOverlaySettings = useMemo(() => {
    if (!promosEnabled) {
      return {
        items_overlay_text: '',
        items_overlay_text_colour: '',
        items_overlay_text_size: 0
      };
    }

    const activeCategorySettings = mainSettings?.main_settings?.category_settings?.find(
      setting => setting.is_active === 1
    );

    return {
      items_overlay_text: overlayText
        ? decodeURIComponent(overlayText)
        : '',
      items_overlay_text_colour: overlayColor
        ? decodeURIComponent(overlayColor)
        : '#FFFFFF',
      items_overlay_text_size: overlaySize
        ? parseInt(overlaySize)
        : 14
    };
  }, [overlayText, overlayColor, overlaySize, mainSettings, promosEnabled]);

  // First, modify the queryKey to not depend on dimensions unless explicitly needed
  const queryKey = useMemo(() => {
    const baseKey = [
      'product-filter',
      item_group,
      application,
      design,
      colour,
      promoId,
      targetProduct,
      promosEnabled
    ];

    // Only include dimensions if we're loading a promo product with target size
    if (targetProduct && targetSize && promosEnabled) {
      if (diameter) {
        return [...baseKey, 'diameter', diameter];
      } else if (width && lengthcm) {
        return [...baseKey, 'dimensions', width, lengthcm];
      }
    }

    return baseKey;
  }, [item_group, application, design, colour, promoId, targetProduct, promosEnabled,
    width, lengthcm, diameter, targetSize]);

// Then modify the query with updated onSuccess
  const {
    isSuccess,
    isError,
    data: productData,
    isLoading,
  } = useQuery(
    queryKey,
    async () => {
      const baseFilter = {
        item_group,
        application,
        design
      };

      const multifilterProducts = await apiFilterProducts({
        filter: baseFilter,
        off_setter: { start: 0, end: 100 },
        focus_keys: ['width', 'length_cm', 'diameter'],
        required_full_key_info: ['width', 'length_cm', 'diameter'],
      });

      if (isPromoProduct && targetProduct && promosEnabled) {
        const promoResponse = await apiGetPromoProductBySku(targetProduct);
        if (!promoResponse?.success || !promoResponse.promo_product) {
          throw new Error('Promo product not found');
        }

        const promoProducts = await apiGetFilteredPromoProducts(
          promoResponse.promo_product.item_group,
          {
            application: [promoResponse.promo_product.application],
            design: [promoResponse.promo_product.design],
            colour: [],
            type: [],
            brand: [],
            material: [],
            standard: [],
            size: [],
            shape: []
          },
          0,
          100
        );

        if (!promoProducts?.success) {
          throw new Error('Failed to fetch promo products');
        }

        const promoSkus = new Set(promoProducts?.promo_products?.map(p => p.product_sku) || []);
        const productItems = multifilterProducts.slice(0, -1) as ProductItem[];
        const additionalInfo = multifilterProducts[multifilterProducts.length - 1];
        const filteredProducts = productItems.filter((item: ProductItem) =>
          promoSkus.has(item.Product?.sku || '')
        );

        return [...filteredProducts, additionalInfo];
      }

      return multifilterProducts;
    },
    {
      enabled: !!item_group && !!application && !!collection && !!design,
      staleTime: 60000,
      cacheTime: 60000,
      onSuccess: (data) => {
        const items = data.slice(0, -1);
        if (items.length === 0) return;

        // Handle initial load with target product
        if (isInitialLoad && targetProduct && targetSize) {
          const targetIndex = items.findIndex((item: ProductItem) => {
            // Ensure we trim values and handle null/undefined
            const itemWidth = item.StockMaster?.width?.trim() || '';
            const itemLength = item.StockMaster?.length_cm?.trim() || '';
            const itemDiameter = item.StockMaster?.diameter?.trim() || '';

            const currentSize = itemDiameter && itemDiameter !== '0cm'
              ? itemDiameter
              : `${itemWidth}x${itemLength}`;

            const sizeMatch = currentSize === targetSize;
            const skuMatch = item.Product?.sku === targetProduct;

            return skuMatch && sizeMatch;
          });

          if (targetIndex !== -1) {
            const item = items[targetIndex];
            if (!item) return;

            // Update all states synchronously
            Promise.resolve().then(() => {
              setCurrentIndex(targetIndex);
              setSelectedImagePreviewIndex(targetIndex);
              setActiveImageStep(0);

              // Set dimensions based on the matched item
              if (item.StockMaster?.diameter && item.StockMaster.diameter !== '0cm') {
                setDiameter(item.StockMaster.diameter.trim());
                setWidth('');
                setLengthCM('');
              } else {
                const width = item.StockMaster?.width?.replace('cm', '').trim() || '';
                const length = item.StockMaster?.length_cm?.replace('cm', '').trim() || '';
                setWidth(width);
                setLengthCM(length);
                setDiameter('');
              }

              // Set isInitialLoad to false after a short delay
              setTimeout(() => {
                setIsInitialLoad(false);
              }, 100);
            });
          } else {
            // If no match is found, still set isInitialLoad to false
            setIsInitialLoad(false);
          }
        } else if (isInitialLoad) {
          // If not looking for a specific product but still in initial load
          setIsInitialLoad(false);
        }
      }
    }
  );









  const productDataItems = productData?.slice(0, -1) || [];

  // Dynamic promo product query - updates when the selected product changes
  const {
    data: dynamicPromoData,
    isLoading: isLoadingDynamicPromo
  } = useQuery(
    ['dynamic-promo-product', productDataItems[currentIndex]?.Product?.sku, currentIndex],
    async () => {
      if (!productDataItems || productDataItems.length === 0 || !isPromoProduct || !promosEnabled)
        return null;

      // Get the current SKU from the selected product
      const currentSku = productDataItems[currentIndex]?.Product?.sku;
      if (!currentSku) return null;

      // If the current SKU matches the original target product, use the original promoData
      if (currentSku === targetProduct && promoData) {
        return promoData;
      }

      // Otherwise fetch the promo data for this specific SKU
      const response = await apiGetPromoProductBySku(currentSku);
      if (response?.success && response.promo_product) {
        return {
          id: response.promo_product.id,
          product_sku: response.promo_product.product_sku,
          original_price_excl: response.promo_product.original_price_excl,
          promo_price_excl: response.promo_product.promo_price_excl,
          original_price_incl: response.promo_product.original_price_incl,
          promo_price_incl: response.promo_product.promo_price_incl
        };
      }
      return null;
    },
    {
      enabled: !!productDataItems &&
        productDataItems.length > 0 &&
        isPromoProduct &&
        promosEnabled &&
        !isInitialLoad,
      staleTime: 60000
    }
  );



  // Product details list
  const productDetailsList =
    productDataItems && productDataItems.length > 0
      ? [
        {
          key: 'Product Code (SKU)',
          value: productDataItems[currentIndex]?.Product?.sku,
        },
        {
          key: 'Description',
          value: productDataItems[currentIndex]?.StockMaster?.description_1,
        },
        {
          key: 'Design',
          value: productDataItems[currentIndex]?.StockMaster?.design,
        },
        {
          key: 'Colour',
          value: productDataItems[currentIndex]?.StockMaster?.colour,
        },
        {
          key: 'Range',
          value: productDataItems[currentIndex]?.StockMaster?.range,
        },
        {
          key: 'Brand',
          value: productDataItems[currentIndex]?.StockMaster?.brand,
        },
        ...(((productDataItems[currentIndex]?.StockMaster?.width &&
            productDataItems[currentIndex]?.StockMaster?.width !== '0cm' &&
            productDataItems[currentIndex]?.StockMaster?.length_cm &&
            productDataItems[currentIndex]?.StockMaster?.length_cm !== '0cm') ||
          (productDataItems[currentIndex]?.StockMaster?.diameter &&
            productDataItems[currentIndex]?.StockMaster?.diameter !== '0cm' &&
            productDataItems[currentIndex]?.StockMaster?.group_desc === 'Rugs')) &&
        (productDataItems[currentIndex]?.StockMaster?.item_group !== '001') &&
        (productDataItems[currentIndex]?.StockMaster?.group_desc !== 'Wallpaper')
          ? [
            {
              key: 'Size',
              value: productDataItems[currentIndex]?.StockMaster?.diameter &&
              productDataItems[currentIndex]?.StockMaster?.diameter !== '0cm'
                ? `Dia ${productDataItems[currentIndex]?.StockMaster?.diameter}`
                : `${productDataItems[currentIndex]?.StockMaster?.width} x ${productDataItems[currentIndex]?.StockMaster?.length_cm}`,
            },
          ]
          : []),
        ...(productDataItems[currentIndex]?.StockMaster?.brand === 'HAUS' &&
        !productDataItems[currentIndex]?.StockMaster?.c_weight
          ?.trim()
          ?.startsWith('0')
          ? [
            {
              key: 'Weight',
              value: productDataItems[currentIndex]?.StockMaster?.c_weight,
            },
          ]
          : []),
        ...(productDataItems[currentIndex]?.StockMaster?.group_desc ===
        'Fabric'
          ? [
            {
              key: 'Width',
              value: productDataItems[currentIndex]?.StockMaster?.width,
            },
          ]
          : []),
        ...(productDataItems[currentIndex]?.StockMaster?.group_desc ===
        'Wallpaper'
          ? [
            {
              key: 'Roll Dimension',
              value: `${productDataItems[currentIndex]?.StockMaster?.width} x ${productDataItems[currentIndex]?.StockMaster?.length_cm}`,
            },
          ]
          : []),
        ...(productDataItems[currentIndex]?.StockMaster?.group_desc ===
        'Fabric' ||
        productDataItems[currentIndex]?.StockMaster?.group_desc ===
        'Wallpaper'
          ? [
            {
              key: 'Vertical Repeat',
              value: productDataItems[currentIndex]?.StockMaster?.repeat_t,
            },
          ]
          : []),
        ...(productDataItems[currentIndex]?.StockMaster?.group_desc ===
        'Wallpaper'
          ? [
            {
              key: 'Installation',
              value:
              productDataItems[currentIndex]?.StockMaster
                ?.hanging_instructions,
            },
          ]
          : []),
        ...(productDataItems[currentIndex]?.StockMaster?.group_desc ===
        'Fabric'
          ? [
            {
              key: 'Horizontal Repeat',
              value: productDataItems[currentIndex]?.StockMaster?.repeat_h,
            },
          ]
          : []),
        ...(productDataItems[currentIndex]?.StockMaster?.group_desc ===
        'Fabric'
          ? [
            {
              key: 'Railroaded',
              value:
              productDataItems[currentIndex]?.StockMaster?.railroaded,
            },
          ]
          : []),
        // Composition for ALL product types
        {
          key: 'Composition',
          value: productDataItems[currentIndex]?.StockMaster?.composition,
        },
        ...(productDataItems[currentIndex]?.StockMaster?.group_desc ===
        'Fabric' &&
        !productDataItems[currentIndex]?.StockMaster?.c_weight
          ?.trim()
          ?.startsWith('0')
          ? [
            {
              key: 'Weight',
              value: productDataItems[currentIndex]?.StockMaster?.c_weight,
            },
          ]
          : []),
        {
          key: 'Care Info',
          value: Array.isArray(
            productDataItems[currentIndex]?.Product?.cleaning_instructions
          ) ? (
            <Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '7px',
                }}
              >
                {productDataItems[
                  currentIndex
                  ]?.Product?.cleaning_instructions.map(
                  (instruction: any, index: any) =>
                    instruction.image_data ? (
                      <img
                        key={index}
                        src={instruction.image_data}
                        alt={instruction.file_name}
                        style={{
                          width: '30px',
                          height: '30px',
                          paddingRight: '0',
                        }}
                      />
                    ) : null
                )}
              </Box>
              <Box>
                <span>
                  {`${productDataItems[
                    currentIndex
                    ]?.Product?.cleaning_instructions
                    .filter((instruction: any) => !instruction.image_data)
                    .map(
                      (instruction: any) =>
                        instruction.cleaning_instruction_name
                    )
                    .join('. ')}${
                    productDataItems[
                      currentIndex
                      ]?.Product?.cleaning_instructions.some(
                      (instruction: any) => !instruction.image_data
                    )
                      ? '.'
                      : ''
                  }`}
                </span>
              </Box>
            </Box>
          ) : null,
        },
        ...(productDataItems[currentIndex]?.StockMaster?.group_desc === 'Fabric' &&
        productDataItems[currentIndex]?.StockMaster?.martindale
          ? [
            {
              key: 'Martindale Rubs',
              value: productDataItems[currentIndex]?.StockMaster?.martindale,
            },
          ]
          : []),
        ...(productDataItems[currentIndex]?.StockMaster?.group_desc ===
        'Fabric'
          ? [
            {
              key: 'Recommended Use',
              value:
              productDataItems[currentIndex]?.StockMaster?.application,
            },
          ]
          : []),
        {
          key: 'Comments',
          value: productDataItems[currentIndex]?.Product?.disambiguating_description ? (
            <Box>
              {productDataItems[currentIndex]?.Product?.disambiguating_description
                .split('.')
                .filter((sentence: string) => sentence.trim() !== '')
                .map((sentence: string, index: number, array: string[]) => (
                  <span key={index}>
          {sentence}{index < array.length - 1 ? '.' : ''}
                    {index < array.length - 1 && <br />}
        </span>
                ))}
            </Box>
          ) : 'N/A',
        },
        {
          key: 'Standards',
          value: Array.isArray(
            productDataItems[currentIndex]?.Product?.standards
          ) ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: '10px',
              }}
            >
              {productDataItems[currentIndex]?.Product?.standards.map(
                (standard: any, index: any) => (
                  <img
                    key={index}
                    src={standard.image_data}
                    alt={standard.file_name}
                    style={{
                      height: '60px',
                      paddingRight: '0',
                    }}
                  />
                )
              )}
            </Box>
          ) : null,
        },
      ]
      : [];


  // Effects
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [colour]);

  useEffect(() => {
    captureAnalytics('pageview', location.pathname, `product-${design}`);
  }, [location.pathname, design]);

  // Loading and error states
  if (isLoading || isLoadingPromo) return <LoadingScreen />;
  if (isError || isErrorPromo) return <MissingPage />;

  return (
    <PageLayout>
      <Container maxWidth="xl">
        <Grid
          mt={{ xs: 5, sm: 10 }}
          container
          sx={{
            flexDirection: {
              xs: 'column',
              lg: 'row',
            },
          }}
        >
          <ImageSection
            productListData={productDataItems}
            currentIndex={currentIndex}
            setCurrentIndex={setCurrentIndex}
            currentSelectedItem={currentSelectedItem}
            selectedImagePreviewIndex={selectedImagePreviewIndex}
            activeImageStep={activeImageStep}
            setActiveImageStep={setActiveImageStep}
            categorySettings={combinedOverlaySettings}
            isPromoProduct={isPromoProduct && promosEnabled}
            promoMap={promoMap}
            promoData={dynamicPromoData || promoData} // Use dynamic data when available
          />


          <ProductSection
            productListData={productDataItems}
            productData={productData}
            currentIndex={currentIndex}
            setCurrentIndex={setCurrentIndex}
            itemGroup={item_group}
            application={application}
            collection={collection}
            design={design}
            colour={colour}
            currentSelectedItem={currentSelectedItem}
            setCurrentSelectedItem={setCurrentSelectedItem}
            setSelectedImagePreviewIndex={setSelectedImagePreviewIndex}
            selectedImagePreviewIndex={selectedImagePreviewIndex}
            setWidth={setWidth}
            setDiameter={setDiameter}
            setLengthCM={setLengthCM}
            diameter={diameter}
            lengthCM={lengthcm}
            width={width}
            activeImageStep={activeImageStep}
            setActiveImageStep={setActiveImageStep}
            isPromoProduct={isPromoProduct && promosEnabled}
            promoData={dynamicPromoData || promoData} // Use dynamic data when available
            designPromoProducts={designPromoProducts}
            isLoadingDesignPromos={isLoadingDesignPromos || isLoadingDynamicPromo} // Include the dynamic promo loading state
            promoMap={promoMap}
            isInitialLoad={isInitialLoad}
            setIsInitialLoad={setIsInitialLoad}
          />
        </Grid>

        <ProductDetails productDetailsList={productDetailsList} />
      </Container>
    </PageLayout>
  );
}

export default ProductPage;