import { baseURL } from './baseUrl';
import { ensureAuthTokenLoaded } from '../utilities/AuthUtils';

export const apiGetCollections = async (
  mainCategory: string,
  applicationName: string
) => {
    // Wait for auth token to be available
    const authToken = await ensureAuthTokenLoaded();

    const response = await baseURL.get(
      `get-collection-by-cat-application?cat=${mainCategory}&application=${applicationName}&start=0&count=5`,
      {
          headers: {
              Authorization: `Bearer ${authToken}`,
              'Content-Type': 'application/json',
          },
      }
    );
    return response.data;
};

export const apiGetCollectionPageData = async (data: object) => {
    // Wait for auth token to be available


    const response = await baseURL.post(`get-all-cms-data`, data, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
    return response.data;
};

export const apiGetYouMayAlsoLikeData = async (stock_link: number) => {
    // Wait for auth token to be available
    const authToken = await ensureAuthTokenLoaded();

    const response = await baseURL.get(`you-may-also-like/${stock_link}`, {
        headers: {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json',
        },
    });
    return response.data;
};

export const apiGetDesignsInCollection = async (filters: object) => {
    // Wait for auth token to be available


    const response = await baseURL.post(`products/multi_filter`, filters, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
    return response.data;
};