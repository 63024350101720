import {
    getCartByUserId,
    setItemAmountInCart,
    editItemAmountInCart,
    removeItemFromCart,
    clearCart,
    finalizeCartWithAccount,
    finalizeCartWithPayfast,
    getCartPaymentStatus,
    updateDeliveryInfo,
    userCredit,
    paymentStatus,
    getStockForItem
} from '../api/apiCart';

class CartService {
    authToken: string;
    // Add this property to track pending stock checks
    private _pendingStockChecks: Record<string, Promise<any>> = {};
    private _lastStockCheck: Record<string, { timestamp: number, result: any }> = {};
    private _stockCheckCooldown = 2000; // 2 seconds between checks for the same SKU

    constructor(authToken: string) {
        this.authToken = authToken;
        this._pendingStockChecks = {};
    }

    async checkAndRetrieveCart() {
        try {
            // This single call will either get an existing cart or create a new one
            const cart = await this.getCartByUser();
            return cart;
        } catch (error) {
            return null;
        }
    }

    async getCartByUser() {
        return await getCartByUserId(this.authToken);
    }

    async setItemAmount(cartId: string, sku: string, quantity: number) {
        console.log("CART SERVICE - Setting item amount:", sku, quantity);
        const response = await setItemAmountInCart(this.authToken, cartId, sku, quantity);
        console.log("CART SERVICE - Set item response:", response);
        return response;
    }

    async editItemAmount(cartId: string, sku: string, quantityChange: number) {
        return await editItemAmountInCart(this.authToken, cartId, sku, quantityChange);
    }

    async deleteItemFromCart(cartId: string, sku: string) {
        return await removeItemFromCart(this.authToken, cartId, sku);
    }

    async clearCartContents(cartId: string) {
        return await clearCart(this.authToken, cartId);
    }

    async updateDeliveryInfo(
      cartId: string,
      deliveryInfo: {
          shipping_address: {
              address_line_1: string;
              address_line_2?: string;
              address_line_3?: string;
              address_line_4?: string;
              address_line_5?: string;
              address_zip: string;
          };
          delivery_type: 'COLLECTION' | 'DELIVERY' | 'THIRD_PARTY_DELIVERY';
          delivery_note?: string;
          customer_order_num?: string;
          contact_person: {
              name: string;
              surname: string;
              phone: string;
          };
          payment_type: '30E' | 'PIA';
      }
    ) {
        return await updateDeliveryInfo(this.authToken, cartId, deliveryInfo);
    }

    async finalizeUserCartWithAccount(cartId: string) {
        return await finalizeCartWithAccount(cartId, this.authToken);
    }

    async finalizeUserCartWithPayfast(cartId: string) {
        return await finalizeCartWithPayfast(cartId, this.authToken);
    }

    async getCartPaymentStatus(cartId: string) {
        return await getCartPaymentStatus(cartId, this.authToken);
    }

    async checkItemStock(sku: string) {
        try {
            // Simple direct API call with cart ID
            const cart = await this.checkAndRetrieveCart();
            const cartId = cart?.id;

            // Single direct API call, no caching logic
            const result = await getStockForItem(this.authToken, sku, cartId);

            console.log('STOCK CHECK result for', sku, {
                stock_on_hand: result.stock_on_hand,
                total_available: result.total_available,
                in_cart: result.in_cart || 'not provided'
            });

            return result;
        } catch (error) {
            console.error('Error checking stock:', error);
            return null;
        }
    }


    async getCreditUser() {
        return await userCredit(this.authToken);
    }

    async getPaymentStatus(cartId: string) {
        return await paymentStatus(this.authToken, cartId);
    }
}

export default CartService;