import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import LoadingScreen from '../../components/shared/loadingScreen/LoadingScreen';
import { getCartByUserId } from '../../api/apiCart';

const PayfastStatus = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const authToken = localStorage.getItem('authToken') || '';

    // Check for cancellation parameter
    const urlParams = new URLSearchParams(window.location.search);
    const payfastCancel = urlParams.get('cancel') === 'true';

    // If cancel is present, go directly to failure page
    useEffect(() => {
        if (payfastCancel) {
            console.log('Payment was canceled by user');
            localStorage.removeItem('lastProcessedCartId');
            setIsLoading(false);
            navigate('/order-failed');
        }
    }, [payfastCancel, navigate]);

    // Set up a fallback timer - if verification takes too long, assume success
    useEffect(() => {
        if (payfastCancel) return; // Don't set up timer if canceled

        const fallbackTimer = setTimeout(() => {
            console.log('Verification timed out - assuming success based on redirect');
            localStorage.removeItem('lastProcessedCartId');
            setIsLoading(false);
            navigate('/success');
        }, 5000); // 5 second fallback

        return () => clearTimeout(fallbackTimer);
    }, [payfastCancel, navigate]);

    // Fetch current cart for verification attempt
    useQuery(
      'current-cart',
      () => getCartByUserId(authToken),
      {
          enabled: !!authToken && !payfastCancel,
          retry: 2,
          staleTime: 0,
          onSuccess: (data) => {
              // If we get a successful cart response and not canceled, proceed to success
              if (!payfastCancel) {
                  console.log('Cart retrieved successfully after PayFast payment');
                  localStorage.removeItem('lastProcessedCartId');
                  setIsLoading(false);
                  navigate('/success');
              }
          },
          onError: () => {
              // If there's an error fetching the cart, we'll let the fallback timer handle it
              console.log('Error fetching cart - fallback timer will handle redirect');
          }
      }
    );

    return <>{isLoading && <LoadingScreen />}</>;
};

export default PayfastStatus;