import React, { useEffect, useState, useRef } from 'react';
import { Grid, Box, Typography, Container, CircularProgress } from '@mui/material';
import { useMutation } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import ProductGrid from '../../../filter/components/ProductGrid';
import PromoProductGrid from "./PromoProductGrid";
import PromoFilterSelection from "./PromoFilterSelection";
import PromoApplicationSelection from "./PromoApplicationSelection";
import PromoClearFilters from "./PromoClearFilters";
import PromoPagination from "./PromoPagination";

// Types
import {
    PromoProduct,
    PromoFilterOptions,
    PromoFilterMutationRequest,
    PromoApiResponse,
    PromoFilters,
    CategorySettingsResponse
} from '../types';

// API
import {
    apiGetFilteredPromoProducts,
    apiGetFiltersForItemGroup,
    apiGetPromoApplicationData
} from "../../../../../api/apiPromos";

interface PromoFilterComponentProps {
    title?: string;
    itemGroup: string;
    initialFilters?: PromoFilters;
    availableFilters?: PromoFilterOptions;
    totalCount?: number;
    categorySettings?: CategorySettingsResponse['category_settings'];
}

interface ApplicationItemGroups {
    [key: string]: string[];
}



const PromoFilterComponent: React.FC<PromoFilterComponentProps> = ({
                                                                       title,
                                                                       itemGroup,
                                                                       initialFilters,
                                                                       availableFilters,
                                                                       totalCount: initialTotalCount,
                                                                       categorySettings
                                                                   }): JSX.Element => {
    const navigate = useNavigate();
    const location = useLocation();
    const anchorRef = useRef<HTMLDivElement>(null);
    const [allPromoProducts, setAllPromoProducts] = useState<PromoProduct[]>([]);
    const itemsPerPage = 9;
    // Application states
    const [availableApplications, setAvailableApplications] = useState<string[]>([]);
    const [applicationsList, setApplicationsList] = useState<string[]>([]);
    const [applicationItemGroups, setApplicationItemGroups] = useState<ApplicationItemGroups>({});

    // Available option states
    const [availableColours, setAvailableColours] = useState<string[]>([]);
    const [availableDesigns, setAvailableDesigns] = useState<string[]>([]);
    const [availableTypes, setAvailableTypes] = useState<string[]>([]);
    const [availableBrands, setAvailableBrands] = useState<string[]>([]);
    const [availableMaterials, setAvailableMaterials] = useState<string[]>([]);
    const [availableStandards, setAvailableStandards] = useState<string[]>([]);
    const [availableSizes, setAvailableSizes] = useState<string[]>([]);
    const [availableShapes, setAvailableShapes] = useState<string[]>([]);

    // Selected value states
    const [colourList, setColourList] = useState<string[]>([]);
    const [designList, setDesignList] = useState<string[]>([]);
    const [typesList, setTypesList] = useState<string[]>([]);
    const [brandList, setBrandList] = useState<string[]>([]);
    const [materialList, setMaterialList] = useState<string[]>([]);
    const [standardsList, setStandardsList] = useState<string[]>([]);
    const [sizeList, setSizeList] = useState<string[]>([]);
    const [shapeList, setShapeList] = useState<string[]>([]);

    // Filtered options states
    const [filteredColours, setFilteredColours] = useState<string[]>([]);
    const [filteredDesigns, setFilteredDesigns] = useState<string[]>([]);
    const [filteredTypes, setFilteredTypes] = useState<string[]>([]);
    const [filteredBrands, setFilteredBrands] = useState<string[]>([]);
    const [filteredMaterials, setFilteredMaterials] = useState<string[]>([]);
    const [filteredStandards, setFilteredStandards] = useState<string[]>([]);
    const [filteredSizes, setFilteredSizes] = useState<string[]>([]);
    const [filteredShapes, setFilteredShapes] = useState<string[]>([]);

    // Pagination states
    const [productItemCount, setProductItemCount] = useState<number>(initialTotalCount || 0);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [totalPages, setTotalPages] = useState<number>(0);


    const getPromoProductsMutation = useMutation<PromoApiResponse, Error, PromoFilterMutationRequest>({
        mutationFn: async (filterRequest: PromoFilterMutationRequest) => {

            return apiGetFilteredPromoProducts(
              filterRequest.item_group,
              filterRequest.filters,
              filterRequest.page || 0,
              filterRequest.items_per_page || itemsPerPage
            );
        },
        onSuccess: (data: PromoApiResponse) => {

            if (!data?.success) return;

            if (Array.isArray(data.promo_products)) {
                setAllPromoProducts(data.promo_products);
            }

            if (data.filter_options) {
                setFilteredColours(data.filter_options.colour || []);
                setFilteredDesigns(data.filter_options.design || []);
                setFilteredTypes(data.filter_options.type || []);
                setFilteredBrands(data.filter_options.brand || []);
                setFilteredMaterials(data.filter_options.material || []);
                setFilteredStandards(data.filter_options.standard || []);
                setFilteredSizes(data.filter_options.size || []);
                setFilteredShapes(data.filter_options.shape || []);
            }

            setProductItemCount(data.total_count || 0);
            setTotalPages(Math.ceil((data.total_count || 0) / itemsPerPage));
        }
    });

    useEffect(() => {
        const fetchInitialFilters = async () => {
            if (!itemGroup) return;

            try {
                const filterResponse = await apiGetFiltersForItemGroup(itemGroup);

                if (filterResponse.success && filterResponse.filters) {
                    // Set available filters
                    setAvailableApplications(filterResponse.filters.application || []);
                    setAvailableColours(filterResponse.filters.colour || []);
                    setAvailableDesigns(filterResponse.filters.design || []);
                    setAvailableTypes(filterResponse.filters.type || []);
                    setAvailableBrands(filterResponse.filters.brand || []);
                    setAvailableMaterials(filterResponse.filters.material || []);
                    setAvailableStandards(filterResponse.filters.standard || []);
                    setAvailableSizes(filterResponse.filters.size || []);
                    setAvailableShapes(filterResponse.filters.shape || []);

                    // Set filtered options
                    setFilteredColours(filterResponse.filters.colour || []);
                    setFilteredDesigns(filterResponse.filters.design || []);
                    setFilteredTypes(filterResponse.filters.type || []);
                    setFilteredBrands(filterResponse.filters.brand || []);
                    setFilteredMaterials(filterResponse.filters.material || []);
                    setFilteredStandards(filterResponse.filters.standard || []);
                    setFilteredSizes(filterResponse.filters.size || []);
                    setFilteredShapes(filterResponse.filters.shape || []);
                }
            } catch (error) {
                console.error('Error fetching initial filters:', error);
            }
        };

        fetchInitialFilters();
    }, [itemGroup]);

    // useEffect(() => {
    //     const fetchAllApplications = async () => {
    //         if (!itemGroup) return;
    //
    //         try {
    //             const filterResponse = await apiGetFiltersForItemGroup(itemGroup);
    //
    //             if (filterResponse.success && filterResponse.filters?.application) {
    //                 setAvailableApplications(filterResponse.filters.application);
    //
    //                 const applicationData = await Promise.all(
    //                   filterResponse.filters.application.map(app =>
    //                     apiGetPromoApplicationData(app)
    //                   )
    //                 );
    //
    //                 const appToItemGroups = applicationData.reduce((acc: ApplicationItemGroups, data) => {
    //                     if (data.application) {
    //                         acc[data.application] = acc[data.application] || [];
    //                         if (data.itemGroup && !acc[data.application].includes(data.itemGroup)) {
    //                             acc[data.application].push(data.itemGroup);
    //                         }
    //                     }
    //                     return acc;
    //                 }, {});
    //
    //                 setApplicationItemGroups(appToItemGroups);
    //             }
    //         } catch (error) {
    //             console.error('Error fetching applications:', error);
    //         }
    //     };
    //
    //     fetchAllApplications();
    // }, [itemGroup]);



    useEffect(() => {
        const initializeFromURL = async () => {
            const currentParams = new URLSearchParams(location.search);



            // Get filter parameters
            const applicationParams = currentParams.getAll('application');
            const colourParams = currentParams.getAll('colour');
            const designParams = currentParams.getAll('design');
            const typeParams = currentParams.getAll('type');
            const brandParams = currentParams.getAll('brand');
            const materialParams = currentParams.getAll('material');
            const standardsParams = currentParams.getAll('standard');
            const sizeParams = currentParams.getAll('size');
            const shapeParams = currentParams.getAll('shape');
            const itemGroupParam = currentParams.get('itemGroup');

            // Set filter states
            setApplicationsList(applicationParams);
            setColourList(colourParams);
            setDesignList(designParams);
            setTypesList(typeParams);
            setBrandList(brandParams);
            setMaterialList(materialParams);
            setStandardsList(standardsParams);
            setSizeList(sizeParams);
            setShapeList(shapeParams);

            // Get pagination parameters
            // Get pagination parameters
            const page = parseInt(currentParams.get('page') || '1');
            const perPage = parseInt(currentParams.get('items_per_page') || itemsPerPage.toString());

            setCurrentPage(page);



            const filters = {
                application: applicationParams,
                colour: colourParams,
                design: designParams,
                type: typeParams,
                brand: brandParams,
                material: materialParams,
                standard: standardsParams,
                size: sizeParams,
                shape: shapeParams
            };

            const targetItemGroup = itemGroupParam || itemGroup;

            try {
                const filterResponse = await apiGetFiltersForItemGroup(targetItemGroup);
                if (filterResponse.success && filterResponse.filters) {
                    setAvailableApplications(filterResponse.filters.application || []);
                    setAvailableColours(filterResponse.filters.colour || []);
                    setAvailableDesigns(filterResponse.filters.design || []);
                    setAvailableTypes(filterResponse.filters.type || []);
                    setAvailableBrands(filterResponse.filters.brand || []);
                    setAvailableMaterials(filterResponse.filters.material || []);
                    setAvailableStandards(filterResponse.filters.standard || []);
                    setAvailableSizes(filterResponse.filters.size || []);
                    setAvailableShapes(filterResponse.filters.shape || []);
                }

                await getPromoProductsMutation.mutateAsync({
                    item_group: targetItemGroup,
                    filters,
                    page: page - 1,
                    items_per_page: perPage
                });
            } catch (error) {
                console.error('Error fetching initial products:', error);
            }
        };

        initializeFromURL();
    }, [location.search, itemGroup]);

    useEffect(() => {
        if (availableFilters) {
            setAvailableApplications(availableFilters.application || []);
            setAvailableColours(availableFilters.colour || []);
            setAvailableDesigns(availableFilters.design || []);
            setAvailableTypes(availableFilters.type || []);
            setAvailableBrands(availableFilters.brand || []);
            setAvailableMaterials(availableFilters.material || []);
            setAvailableStandards(availableFilters.standard || []);
            setAvailableSizes(availableFilters.size || []);
            setAvailableShapes(availableFilters.shape || []);
        }
    }, [availableFilters]);

    const handleCheckboxChange = (
      elem: string | number,
      setList: React.Dispatch<React.SetStateAction<string[]>>,
      list: string[],
      paramKey: string
    ) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const isChecked = event.target.checked;
        const elemStr = elem.toString();

        let updatedList: string[];
        if (isChecked) {
            updatedList = [...list, elemStr];
            setList(updatedList);
        } else {
            updatedList = list.filter((item) => item !== elemStr);
            setList(updatedList);
        }
    };

    const updatePaginationParams = (params: { [key: string]: string }): void => {
        const currentParams = new URLSearchParams(location.search);

        // Preserve itemGroup if it exists
        const itemGroup = currentParams.get('itemGroup');
        if (itemGroup) {
            params.itemGroup = itemGroup;
        }

        // Update or add new parameters while maintaining existing ones
        Object.entries(params).forEach(([key, value]) => {
            currentParams.set(key, value);
        });

        // Ensure consistent parameter naming
        if (currentParams.has('limit')) {
            currentParams.set('items_per_page', currentParams.get('limit')!);
            currentParams.delete('limit');
        }

        // Remove offset as we're using page-based pagination
        currentParams.delete('offset');

        navigate(`${location.pathname}?${currentParams.toString()}`, { replace: true });
    };


    const applyFilters = () => {
        setAllPromoProducts([]);

        const finalItemGroups = applicationsList.length > 0
          ? Array.from(new Set([
              itemGroup,
              ...applicationsList.flatMap(app => applicationItemGroups[app] || [])
          ]))
          : itemGroup;

        const filters: PromoFilterMutationRequest['filters'] = {
            application: applicationsList,
            colour: colourList,
            design: designList,
            type: typesList,
            brand: brandList,
            material: materialList,
            standard: standardsList,
            size: sizeList,
            shape: shapeList
        };

        getPromoProductsMutation.mutate({
            item_group: finalItemGroups,
            filters,
            page: 0,
            items_per_page: itemsPerPage
        }, {
            onSuccess: (data: PromoApiResponse) => {
                if (!data?.success) return;

                if (Array.isArray(data.promo_products)) {
                    setAllPromoProducts(data.promo_products);
                }

                if (data.filter_options) {
                    setFilteredColours(data.filter_options.colour || []);
                    setFilteredDesigns(data.filter_options.design || []);
                    setFilteredTypes(data.filter_options.type || []);
                    setFilteredBrands(data.filter_options.brand || []);
                    setFilteredMaterials(data.filter_options.material || []);
                    setFilteredStandards(data.filter_options.standard || []);
                    setFilteredSizes(data.filter_options.size || []);
                    setFilteredShapes(data.filter_options.shape || []);
                }

                setProductItemCount(data.total_count || 0);
                setTotalPages(Math.ceil((data.total_count || 0) / itemsPerPage));
            },
            onError: (error) => {
                console.error('Error applying filters:', error);
            }
        });

        // Update URL parameters while preserving itemGroup
        const currentParams = new URLSearchParams(location.search);

        // Preserve itemGroup if it exists
        const currentItemGroup = currentParams.get('itemGroup');

        // Clear existing parameters but keep itemGroup if it exists
        currentParams.forEach((value, key) => {
            if (key !== 'itemGroup') {
                currentParams.delete(key);
            }
        });

        // Add filter parameters
        Object.entries(filters).forEach(([key, values]) => {
            if (values && values.length > 0) {
                values.forEach(value => currentParams.append(key, value));
            }
        });

        // Ensure itemGroup is set if it existed
        if (currentItemGroup) {
            currentParams.set('itemGroup', currentItemGroup);
        }

        // Add pagination parameters
        currentParams.set('page', '1');
        currentParams.set('items_per_page', itemsPerPage.toString());

        // Update URL without navigation
        window.history.replaceState(
          {},
          '',
          `${window.location.pathname}?${currentParams.toString()}`
        );

        setCurrentPage(1);

        // Scroll to anchor
        if (anchorRef.current) {
            window.scrollTo({
                top: anchorRef.current.offsetTop - 65,
                behavior: 'smooth'
            });
        }
    };

    const clearFilters = () => {
        // Reset all state arrays
        setApplicationsList([]);
        setColourList([]);
        setDesignList([]);
        setTypesList([]);
        setBrandList([]);
        setMaterialList([]);
        setStandardsList([]);
        setSizeList([]);
        setShapeList([]);

        // Get current URL parameters
        const currentParams = new URLSearchParams(location.search);
        const currentItemGroup = currentParams.get('itemGroup');

        // Fetch initial data with empty arrays
        getPromoProductsMutation.mutate({
            item_group: itemGroup,
            filters: {
                application: [],
                colour: [],
                design: [],
                type: [],
                brand: [],
                material: [],
                standard: [],
                size: [],
                shape: []
            },
            page: 0,
            items_per_page: itemsPerPage
        });

        // Create new URL parameters preserving essential ones
        const newParams = new URLSearchParams();
        if (currentItemGroup) {
            newParams.set('itemGroup', currentItemGroup);
        }
        newParams.set('page', '1');
        newParams.set('items_per_page', itemsPerPage.toString());

        // Update URL while preserving essential parameters
        const newUrl = `${window.location.pathname}?${newParams.toString()}`;
        window.history.replaceState({}, '', newUrl);

        // Reset pagination
        setCurrentPage(1);

        // Scroll back to top
        if (anchorRef.current) {
            window.scrollTo({
                top: anchorRef.current.offsetTop - 65,
                behavior: 'smooth'
            });
        }
    };

    const nextPage = () => {
        if (currentPage === totalPages) return;

        if (anchorRef.current) {
            window.scrollTo({
                top: anchorRef.current.offsetTop - 65,
                behavior: 'smooth'
            });
        }

        const nextPageNum = currentPage + 1;
        setAllPromoProducts([]);

        const finalItemGroups = applicationsList.length > 0
          ? Array.from(new Set([
              itemGroup,
              ...applicationsList.flatMap(app => applicationItemGroups[app] || [])
          ]))
          : itemGroup;

        // Create the current filter state
        const currentFilters = {
            application: applicationsList,
            colour: colourList,
            design: designList,
            type: typesList,
            brand: brandList,
            material: materialList,
            standard: standardsList,
            size: sizeList,
            shape: shapeList
        };

        // Update URL parameters while preserving itemGroup
        const currentParams = new URLSearchParams(location.search);

        // Preserve itemGroup
        const currentItemGroup = currentParams.get('itemGroup');
        if (currentItemGroup) {
            currentParams.set('itemGroup', currentItemGroup);
        }

        // Add all current filters to URL params
        Object.entries(currentFilters).forEach(([key, values]) => {
            currentParams.delete(key); // Clear existing values
            if (values && values.length > 0) {
                values.forEach(value => currentParams.append(key, value));
            }
        });

        // Add pagination parameters
        currentParams.set('page', nextPageNum.toString());
        currentParams.set('items_per_page', itemsPerPage.toString());
        currentParams.delete('offset'); // Remove offset parameter
        currentParams.delete('limit');  // Remove limit parameter

        // Update URL without navigation
        window.history.replaceState(
          {},
          '',
          `${window.location.pathname}?${currentParams.toString()}`
        );

        // Make API call with current filters
        getPromoProductsMutation.mutate({
            item_group: finalItemGroups,
            filters: currentFilters,
            page: nextPageNum - 1,
            items_per_page: itemsPerPage
        });

        setCurrentPage(nextPageNum);
    };

    const previousPage = () => {
        if (currentPage === 1) return;

        if (anchorRef.current) {
            window.scrollTo({
                top: anchorRef.current.offsetTop - 65,
                behavior: 'smooth'
            });
        }

        const prevPageNum = currentPage - 1;
        setAllPromoProducts([]);

        const finalItemGroups = applicationsList.length > 0
          ? Array.from(new Set([
              itemGroup,
              ...applicationsList.flatMap(app => applicationItemGroups[app] || [])
          ]))
          : itemGroup;

        // Create the current filter state
        const currentFilters = {
            application: applicationsList,
            colour: colourList,
            design: designList,
            type: typesList,
            brand: brandList,
            material: materialList,
            standard: standardsList,
            size: sizeList,
            shape: shapeList
        };

        // Update URL parameters while preserving itemGroup
        const currentParams = new URLSearchParams(location.search);

        // Preserve itemGroup
        const currentItemGroup = currentParams.get('itemGroup');
        if (currentItemGroup) {
            currentParams.set('itemGroup', currentItemGroup);
        }

        // Add all current filters to URL params
        Object.entries(currentFilters).forEach(([key, values]) => {
            currentParams.delete(key); // Clear existing values
            if (values && values.length > 0) {
                values.forEach(value => currentParams.append(key, value));
            }
        });

        // Add pagination parameters
        currentParams.set('page', prevPageNum.toString());
        currentParams.set('items_per_page', itemsPerPage.toString());
        currentParams.delete('offset'); // Remove offset parameter
        currentParams.delete('limit');  // Remove limit parameter

        // Update URL without navigation
        window.history.replaceState(
          {},
          '',
          `${window.location.pathname}?${currentParams.toString()}`
        );

        getPromoProductsMutation.mutate({
            item_group: finalItemGroups,
            filters: currentFilters,
            page: prevPageNum - 1,
            items_per_page: itemsPerPage
        });

        setCurrentPage(prevPageNum);
    };

    const lastPage = () => {
        if (currentPage === totalPages) return;

        // Add scroll behavior here
        if (anchorRef.current) {
            window.scrollTo({
                top: anchorRef.current.offsetTop - 65,
                behavior: 'smooth'
            });
        }

        updatePaginationParams({
            page: totalPages.toString(),
            items_per_page: itemsPerPage.toString()
        });

        getPromoProductsMutation.mutate({
            item_group: itemGroup,
            filters: {
                application: applicationsList,
                colour: colourList,
                design: designList,
                type: typesList,
                brand: brandList,
                material: materialList,
                standard: standardsList,
                size: sizeList,
                shape: shapeList
            },
            page: totalPages - 1,
            items_per_page: itemsPerPage
        });

        setCurrentPage(totalPages);
    };

    const firstPage = () => {
        if (currentPage === 1) return;

        // Add scroll behavior here
        if (anchorRef.current) {
            window.scrollTo({
                top: anchorRef.current.offsetTop - 65,
                behavior: 'smooth'
            });
        }

        updatePaginationParams({
            page: '1',
            items_per_page: itemsPerPage.toString()
        });

        getPromoProductsMutation.mutate({
            item_group: itemGroup,
            filters: {
                application: applicationsList,
                colour: colourList,
                design: designList,
                type: typesList,
                brand: brandList,
                material: materialList,
                standard: standardsList,
                size: sizeList,
                shape: shapeList
            },
            page: 0,
            items_per_page: itemsPerPage
        });

        setCurrentPage(1);
    };

    return (
      <Container maxWidth="xl">
          <Typography
            variant="h3"
            mb={1}
            mt={10}
            ml={2}
            fontWeight={400}
            fontSize={'40px'}
            textTransform={'capitalize'}
          >
              {` ${title}`}
          </Typography>

          <Box ref={anchorRef}>
              <Grid
                container
                sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', lg: 'row' },
                    height: 'auto',
                    width: '100%',
                    padding: '1rem',
                    m: '4rem 0',
                }}
              >
                  <Box
                    sx={{
                        flex: { xs: 12, lg: 4 },
                        height: '100%',
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        mr: { xs: 0, lg: 5 },
                    }}
                  >
                      <PromoClearFilters clearFilters={clearFilters} />
                      {availableApplications.length > 1 && (
                        <PromoApplicationSelection
                          availableApplications={availableApplications}
                          handleCheckboxChange={handleCheckboxChange}
                          applicationsList={applicationsList}
                          setApplicationsList={setApplicationsList}
                        />
                      )}

                      <PromoFilterSelection
                        availableColours={availableColours}
                        availableDesigns={availableDesigns}
                        availableTypes={availableTypes}
                        availableBrands={availableBrands}
                        availableMaterials={availableMaterials}
                        availableStandards={availableStandards}
                        availableSizes={availableSizes}
                        availableShapes={availableShapes}
                        filteredColours={filteredColours}
                        filteredDesigns={filteredDesigns}
                        filteredTypes={filteredTypes}
                        filteredBrands={filteredBrands}
                        filteredMaterials={filteredMaterials}
                        filteredStandards={filteredStandards}
                        filteredSizes={filteredSizes}
                        filteredShapes={filteredShapes}
                        colourList={colourList}
                        designList={designList}
                        typesList={typesList}
                        brandList={brandList}
                        materialList={materialList}
                        standardsList={standardsList}
                        sizeList={sizeList}
                        shapeList={shapeList}
                        setColourList={setColourList}
                        setDesignList={setDesignList}
                        setTypesList={setTypesList}
                        setBrandList={setBrandList}
                        setMaterialList={setMaterialList}
                        setStandardsList={setStandardsList}
                        setSizeList={setSizeList}
                        setShapeList={setShapeList}
                        handleCheckboxChange={handleCheckboxChange}
                        applicationsList={applicationsList}
                        anchorRef={anchorRef}
                        applyFilters={applyFilters}
                      />
                  </Box>
                  <Box
                    flex={{ xs: 12, lg: 8 }}
                    sx={{
                        height: '100%',
                        flexDirection: 'column',
                    }}
                  >
                      {getPromoProductsMutation.isLoading ? (
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '80vh'
                        }}>
                            <CircularProgress />
                        </Box>
                      ) : getPromoProductsMutation?.data?.promo_products?.length === 0 ? (
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            width: '100%',
                            height: '80vh',
                        }}>
                            <Typography fontSize={'1.5rem'} fontWeight={'bold'}>
                                No promotional items found
                            </Typography>
                            <Typography fontSize={'1.5rem'} fontWeight={'bold'}>
                                Please try a different selection
                            </Typography>
                        </Box>
                      ) : (
                        <PromoProductGrid
                          productItemCount={productItemCount}
                          products={allPromoProducts}
                          getProductsMutation={getPromoProductsMutation}
                          totalPages={totalPages}
                          currentPage={currentPage}
                          previousPage={previousPage}
                          nextPage={nextPage}
                          lastPage={lastPage}
                          firstPage={firstPage}
                          anchorRef={anchorRef}
                          categorySettings={categorySettings}
                        />
                      )}

                  </Box>
              </Grid>
          </Box>
      </Container>
    );
};

export default PromoFilterComponent;