import { Box, Grid, Typography } from '@mui/material';

interface CatalogueGridProps {
    header: string;
    catalogueData: { id: string; img: string; url: string }[];
}

const CatalogueGrid = ({ header, catalogueData }: CatalogueGridProps) => {
    return (
        <Box
            sx={{
                background: '#EFEFEF',
                py: { xs: '20px', lg: '103px' },
                px: '40px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Typography
                sx={{
                    fontWeight: '500',
                    color: '#000',
                    fontSize: '56px',
                    lineHeight: '32px',
                    mb: 8,
                }}
                variant='h1'
            >
                {header}
            </Typography>

            <Grid container spacing={8}>
                {catalogueData
                    .filter(elem => elem.id !== '001')
                    .map((elem, index) => (
                        <Grid item xs={12} sm={3} key={index}>
                            <Box
                                sx={{
                                    width: {
                                        xs: '100%',
                                        md: '100%',
                                        cursor: 'pointer',
                                    },
                                }}
                                onClick={() => {
                                    window.open(elem.url, '_blank');
                                }}
                            >
                                <img
                                    src={elem.img}
                                    alt={`Catalogue ${index + 1}`}
                                    style={{ width: '100%', height: '100%' }}
                                />
                            </Box>
                        </Grid>
                    ))}
            </Grid>
        </Box>
    );
};

export default CatalogueGrid;
