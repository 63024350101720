import { Box, Checkbox, FormControlLabel, FormGroup } from '@mui/material';

type PromoFilterTypeElement =
    | string
    | { idx: number | string; [key: string]: any };

interface PromoFilterProps {
    filterType: PromoFilterTypeElement[];
    filteredOptions: Array<{ id: number } | string>;
    handleCheckboxChange: (
        elem: string | number,
        setList: React.Dispatch<React.SetStateAction<string[]>>,
        list: string[],
        paramKey: string
    ) => (event: React.ChangeEvent<HTMLInputElement>) => void;
    list: string[];
    setList: React.Dispatch<React.SetStateAction<string[]>>;
    keyName: string;
    paramKey: string;
}

const PromoFilter = ({
                         filterType,
                         filteredOptions,
                         handleCheckboxChange,
                         list,
                         setList,
                         keyName,
                         paramKey,
                     }: PromoFilterProps) => {
    const isItemFiltered = (value: string | number): boolean => {
        if (!filteredOptions || filteredOptions.length === 0) return true;

        const valueStr = value.toString().toLowerCase();

        if (typeof filteredOptions[0] === 'string') {
            return filteredOptions
                .map(option => option.toString().toLowerCase())
                .includes(valueStr);
        } else {
            return filteredOptions.some(option =>
                typeof option === 'object' &&
                'id' in option &&
                option.id.toString() === valueStr
            );
        }
    };

    return (
        <Box sx={{ overflowY: 'auto', maxHeight: '20.5rem' }}>
            <FormGroup>
                {filterType
                    .slice()
                    .sort((a, b) => {
                        const aValue = typeof a === 'string' ? a : (a[keyName]?.toString() || '');
                        const bValue = typeof b === 'string' ? b : (b[keyName]?.toString() || '');
                        return aValue.localeCompare(bValue);
                    })
                    .map((elem, index) => {
                        let key: string | number;
                        let label: string;
                        let value: string;

                        if (typeof elem === 'string') {
                            key = `${elem}-${index}`;
                            label = elem;
                            value = elem;
                        } else {
                            key = elem.idx;
                            label = elem[keyName] ? elem[keyName] : 'Unknown';
                            value = elem.idx.toString();
                        }

                        if (!isItemFiltered(value)) return null;

                        return (
                            <FormControlLabel
                                key={key}
                                control={
                                    <Checkbox
                                        checked={list.includes(value)}
                                        onChange={handleCheckboxChange(
                                            value,
                                            setList,
                                            list,
                                            paramKey
                                        )}
                                    />
                                }
                                label={label}
                            />
                        );
                    })}
            </FormGroup>
        </Box>
    );
};

export default PromoFilter;