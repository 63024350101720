import { baseURL } from "./baseUrl";
import { ensureAuthTokenLoaded } from "../utilities/AuthUtils";

export const apiGetAllCategories = async () => {
  // Wait for auth token to be available
  const authToken = await ensureAuthTokenLoaded();

  const response = await baseURL.get("get-all-categories", {
    headers: {
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'application/json',
    },
  });
  return response.data;
};

export const apiGetApplicationNamesByItemGroup = async (
  itemGroup: string
): Promise<{ applications: string[] }> => {
  // Wait for auth token to be available
  const authToken = await ensureAuthTokenLoaded();

  const response = await baseURL.get(
    `products/item_group/get_all_applications/${itemGroup}`,
    {
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
    }
  );
  const data = response.data as { applications: string[] };
  return data;
};

export const apiGetCategoryMainPageData = async (category: object) => {
  // Wait for auth token to be available
  const authToken = await ensureAuthTokenLoaded();

  const response = await baseURL.post(`get-all-cms-data`, category, {
    headers: {
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'application/json',
    },
  });
  return response.data;
};