import React, { RefObject, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import { UseMutationResult } from 'react-query';
import Pagination from './PromoPagination';
import {
  PromoProduct,
  PromoApiResponse,
  PromoFilterMutationRequest,
  CategorySettingsResponse
} from '../types';

interface PromoProductGridProps {
  productItemCount: number;
  getProductsMutation: UseMutationResult<PromoApiResponse, Error, PromoFilterMutationRequest>;
  products?: PromoProduct[];
  totalPages: number;
  currentPage: number;
  previousPage: () => void;
  nextPage: () => void;
  lastPage: () => void;
  firstPage: () => void;
  anchorRef: RefObject<HTMLDivElement>;
  categorySettings?: CategorySettingsResponse['category_settings'];
}

const PromoProductGrid: React.FC<PromoProductGridProps> = ({
                                                             productItemCount,
                                                             getProductsMutation,
                                                             products,
                                                             totalPages,
                                                             currentPage,
                                                             previousPage,
                                                             nextPage,
                                                             lastPage,
                                                             firstPage,
                                                             anchorRef,
                                                             categorySettings,
                                                           }) => {
  // Log sorted products
  const sortedProducts = useMemo(() => {
    const productsToSort = products || getProductsMutation.data?.promo_products || [];

    return [...productsToSort].sort((a, b) => {
      const designA = (a.design || '').toLowerCase();
      const designB = (b.design || '').toLowerCase();
      return designA.localeCompare(designB);
    });
  }, [products, getProductsMutation.data?.promo_products]);

  // Add logging to getSizeString
  const getSizeString = (product: PromoProduct) => {

    if (!product.size || product.size === '-') return '';

    // Handle diameter case
    if (product.size.toLowerCase().includes('diameter')) {
      return product.size;
    }

    // Handle width x length case
    const dimensions = product.size.split('x').map(s => s.trim());
    if (dimensions.length === 2) {
      return `${dimensions[0]}x${dimensions[1]}`;
    }

    return product.size;
  };


  const getProductUrl = (product: PromoProduct) => {

    const sizeString = product.diameter && product.diameter !== '0cm'
        ? product.diameter.trim()
        : `${product.width?.trim()}x${product.length_cm?.trim()}`

    return `/item_group/${product.item_group}/application/${encodeURIComponent(
      product.application
    )}/collection/${encodeURIComponent(
      product.product_collection
    )}/design/${encodeURIComponent(
      product.design
    )}/colour/${encodeURIComponent(
      product.colour
    )}?promoId=${product.id}&targetSize=${
      encodeURIComponent(sizeString)  // Now exactly matches "140cmx0cm"
    }&targetProduct=${
      encodeURIComponent(product.product_sku)
    }&overlayText=${
      encodeURIComponent(categorySettings?.items_overlay_text || 'SALE')
    }&overlayColor=${
      encodeURIComponent(categorySettings?.items_overlay_text_colour || '#FFFFFF')
    }&overlaySize=${
      categorySettings?.items_overlay_text_size || 14
    }`;
  };

  if (getProductsMutation.isLoading) {
    return (
      <Box sx={{
        height: '50rem',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
        <CircularProgress />
      </Box>
    );
  }

  if (getProductsMutation.isError) {
    return <Typography>An error occurred, please try a different filter</Typography>;
  }

  return (
    <>
      <Box ref={anchorRef}>
        <Pagination
          totalPages={totalPages}
          currentPage={currentPage}
          previousPage={previousPage}
          nextPage={nextPage}
          productItemCount={productItemCount}
          lastPage={lastPage}
          firstPage={firstPage}
          currentProducts={sortedProducts}
        />
      </Box>
      <Grid container spacing={2} pl={0} pr={0}>
        {sortedProducts.map((product, index) => (
          product.is_active === 1 ? (
            <Grid item xs={6} sm={4} key={product.id || index}>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Link
                  to={getProductUrl(product)}
                  style={{ textDecoration: 'none', color: 'inherit' }}
                >
                  <Box
                    sx={{
                      position: 'relative',
                      width: '100%',
                      height: { xs: '150px', sm: '300px' },
                      cursor: 'pointer',
                    }}
                  >
                    {/* Promo Overlay */}
                    <Box
                      sx={{
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        bgcolor: categorySettings?.items_overlay_text_colour || '#FFFFFF',
                        color: '#FFFFFF',
                        px: 2,
                        py: 0.75,
                        zIndex: 1,
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: `${categorySettings?.items_overlay_text_size || 14}px`,
                          fontWeight: 600,
                        }}
                      >
                        {categorySettings?.items_overlay_text || 'SALE'}
                      </Typography>
                    </Box>

                    {/* Product Image */}
                    <img
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                      }}
                      src={product.primary_image}
                      alt={`${product.design} in ${product.colour}`}
                    />
                  </Box>

                  {/* Product Details */}
                  <Typography
                    fontWeight="600"
                    fontSize="18px"
                    lineHeight={'33px'}
                    textTransform={'capitalize'}
                    mt={1}
                    mb={0}
                  >
                    {product.design}
                  </Typography>
                  <Typography fontWeight="400" fontSize="16px">
                    {product.colour}
                  </Typography>
                  {product.size !== '-' && (
                    <Typography
                      fontWeight="400"
                      fontSize="14px"
                      mb={1}
                      fontStyle="italic"
                    >
                      {getSizeString(product)}
                    </Typography>
                  )}
                </Link>
              </Box>
            </Grid>
          ) : null
        ))}
      </Grid>
      <Pagination
        totalPages={totalPages}
        currentPage={currentPage}
        previousPage={previousPage}
        nextPage={nextPage}
        productItemCount={productItemCount}
        lastPage={lastPage}
        firstPage={firstPage}
        currentProducts={sortedProducts}
      />
    </>
  );
};

export default PromoProductGrid;