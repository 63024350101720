import React, { RefObject } from 'react';
import { Link } from 'react-router-dom';
import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import { UseMutationResult } from 'react-query';
import Pagination from './Pagination';
import { CategorySettings, PromoProduct } from '../../pageTemplates/promo/types';

interface Product {
  sku: string;
  collection: string;
  primaryImageUrl: string;
  is_published: boolean;
  colour: string;
}

interface StockMaster {
  item_group: string;
  application: string;
  design: string;
  colour: string;
  web_size: string;
  diameter: string;
  width: string;
  length_cm: string;
  range: string;
}

interface ProductData {
  Product: Product;
  StockMaster: StockMaster;
}

interface FilterMutationRequest {
  filter: {
    item_group: string;
  };
  off_setter: {
    start: number;
    end: number;
  };
  required_fields: {
    item_group: string;
    Applications: string[];
    WebTypes: number[];
    WebColors: number[];
    WebDesignStyles: number[];
    WebBrands: number[];
    WebMaterials: number[];
    WebStandards: number[];
    WebSizes: string[];
    WebShapes: string[];
  };
}

interface ProductGridProps {
  productItemCount: number;
  getProductsMutation: UseMutationResult<any[], Error, FilterMutationRequest>;
  totalPages: number;
  currentPage: number;
  previousPage: () => void;
  nextPage: () => void;
  lastPage: () => void;
  firstPage: () => void;
  anchorRef: RefObject<HTMLDivElement>;
  categorySettings?: CategorySettings;
  promoProducts: PromoProduct[];
}

const ProductGrid: React.FC<ProductGridProps> = ({
                                                   productItemCount,
                                                   getProductsMutation,
                                                   totalPages,
                                                   currentPage,
                                                   previousPage,
                                                   nextPage,
                                                   lastPage,
                                                   firstPage,
                                                   anchorRef,
                                                   categorySettings,
                                                   promoProducts = [],
                                                 }) => {
  return (
    <>
      <Box ref={anchorRef}>
        <Pagination
          totalPages={totalPages}
          currentPage={currentPage}
          previousPage={previousPage}
          nextPage={nextPage}
          productItemCount={productItemCount}
          lastPage={lastPage}
          firstPage={firstPage}
        />
      </Box>
      <Grid container spacing={2} pl={0} pr={0}>
        {getProductsMutation.isLoading ? (
          <Box
            sx={{
              height: '50rem',
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CircularProgress />
          </Box>
        ) : getProductsMutation.isError ? (
          <Typography>An error occurred, please try a different filter</Typography>
        ) : (
          <>
            {getProductsMutation.data
              ?.sort((a, b) =>
                (a.StockMaster?.design || '').toLowerCase()
                  .localeCompare((b.StockMaster?.design || '').toLowerCase())
              )
              .map((elem, index) => {
                // Check if this product is in promoProducts
                const promoProduct = promoProducts.find(
                  promo =>
                    promo.product_sku === elem.Product?.sku &&
                    promo.size === elem.StockMaster?.web_size
                );

                // Match exactly how ProductGrid formats size - no spaces
                const sizeString = elem.StockMaster?.diameter && elem.StockMaster.diameter !== '0cm'
                  ? elem.StockMaster.diameter.trim()
                  : `${elem.StockMaster?.width?.trim()}x${elem.StockMaster?.length_cm?.trim()}`;


                // Generate URL based on whether it's a promo product or not
                const productUrl = promoProduct
                  ? `/item_group/${elem.StockMaster?.item_group}/application/${encodeURIComponent(
                    elem.StockMaster?.application
                  )}/collection/${encodeURIComponent(
                    elem.StockMaster?.range
                  )}/design/${encodeURIComponent(
                    elem.StockMaster?.design
                  )}/colour/${encodeURIComponent(
                    elem.StockMaster?.colour
                  )}?promoId=${promoProduct.id}&targetSize=${
                    encodeURIComponent(sizeString)
                  }&targetProduct=${
                    encodeURIComponent(elem.Product?.sku)
                  }&overlayText=${
                    encodeURIComponent(categorySettings?.items_overlay_text || 'SALE')
                  }&overlayColor=${
                    encodeURIComponent(categorySettings?.items_overlay_text_colour || '#FFFFFF')
                  }&overlaySize=${
                    categorySettings?.items_overlay_text_size || 14
                  }`
                  : `/item_group/${elem.StockMaster?.item_group}/application/${encodeURIComponent(
                    elem.StockMaster?.application
                  )}/collection/${encodeURIComponent(
                    elem.StockMaster?.range
                  )}/design/${encodeURIComponent(
                    elem.StockMaster?.design
                  )}/colour/${encodeURIComponent(
                    elem.StockMaster?.colour
                  )}?targetSize=${encodeURIComponent(sizeString)}&targetProduct=${encodeURIComponent(elem.Product?.sku)}`;

                return elem.Product?.is_published === true ? (
                  <Grid item xs={6} sm={4} key={index}>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      <Link
                        to={productUrl}
                        style={{ textDecoration: 'none', color: 'inherit' }}
                      >
                        <Box
                          sx={{
                            position: 'relative',
                            width: '100%',
                            height: { xs: '150px', sm: '300px' },
                            cursor: 'pointer',
                          }}
                        >
                          {/* Show promo overlay if it's a promo product */}
                          {promoProduct && categorySettings && (
                            <Box
                              sx={{
                                position: 'absolute',
                                bottom: 0,
                                left: 0,
                                bgcolor: categorySettings.items_overlay_text_colour || '#FFFFFF',
                                color: '#FFFFFF',
                                px: 2,
                                py: 0.75,
                                zIndex: 1,
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: `${categorySettings.items_overlay_text_size || 14}px`,
                                  fontWeight: 600,
                                }}
                              >
                                {categorySettings.items_overlay_text || 'SALE'}
                              </Typography>
                            </Box>
                          )}
                          <img
                            style={{
                              width: '100%',
                              height: '100%',
                              objectFit: 'cover',
                            }}
                            src={elem.Product?.primaryImageUrl}
                            alt={`${elem.StockMaster?.design} in ${elem.StockMaster?.colour}`}
                          />
                        </Box>
                        <Typography
                          fontWeight="600"
                          fontSize="18px"
                          lineHeight={'33px'}
                          textTransform={'capitalize'}
                          mt={1}
                          mb={0}
                        >
                          {elem.StockMaster?.design}
                        </Typography>
                        <Typography fontWeight="400" fontSize="16px">
                          {elem.Product?.colour || elem.StockMaster?.colour}
                        </Typography>
                        {elem.StockMaster?.item_group === '004' && (
                          <Typography
                            fontWeight="400"
                            fontSize="14px"
                            mb={1}
                          >
                            {sizeString}
                          </Typography>
                        )}
                      </Link>
                    </Box>
                  </Grid>
                ) : null;
              })}
          </>
        )}
      </Grid>
      <Pagination
        totalPages={totalPages}
        currentPage={currentPage}
        previousPage={previousPage}
        nextPage={nextPage}
        productItemCount={productItemCount}
        lastPage={lastPage}
        firstPage={firstPage}
      />
    </>
  );
};

export default ProductGrid;