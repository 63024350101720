import React, { useState } from 'react';
import {
    Box,
    Grid,
    Divider,
    Typography,
    Container,
    Stack,
    Link,
} from '@mui/material';
import logo from '../../../assets/logos/hertex-logo-light.svg';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import {
    fabricsData,
    rugsData,
    companyData,
    homewareData,
    contactData,
    wallpaperData,
} from './footerData';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import {
    ProductStatResponseModel,
    ProductStatsModel,
} from '../../../api/apiProducts';
import { apiProductProperties } from '../../../api/apiMultiFilter';

function Footer() {
    const dateNow = new Date();
    const [brandsContent, setBrandsContent] = useState<string[]>([]);

    const navigate = useNavigate();

    useQuery<ProductStatResponseModel, Error>(
        'brands',
        () =>
            apiProductProperties({
                data: {
                    items: {
                        get_count: false,
                        item_group: '',
                        required_item_info: ['brand'],
                    },
                },
            } as ProductStatsModel),
        {
            onSuccess: (data) => {
                const apiArray: string[] | undefined = data.brand;
                const internationals = 'Internationals';
                setBrandsContent([...(apiArray ?? []), internationals]);
            },
            onError: (error) => {
                console.error(error);
            },
            cacheTime: 15 * 60 * 1000,
        }
    );

    return (
        <Grid
            sx={{
                backgroundColor: '#000000', // Preserved background color
                width: '100%',
                color: '#fff', // Preserved text color
                padding: { xs: '20px 0', md: '80px 64px' }, // Preserved padding
            }}
        >
            <Container maxWidth="xl">
                {/* Logo Section */}
                <Box
                    onClick={() => navigate('/')}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '93px',
                        cursor: 'pointer',
                        mb: 4, // Added margin bottom for spacing
                    }}
                >
                    <img
                        style={{ width: '221px', height: '43px' }}
                        src={logo}
                        alt="Hertex Logo"
                    />
                </Box>

                {/* Footer Links */}
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', lg: 'row' },
                        justifyContent: 'space-between',
                        paddingBottom: '64px',
                    }}
                >
                    {/* Fabrics Section */}
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            paddingBottom: '20px',
                        }}
                    >
                        <Typography
                            sx={{
                                mb: 2,
                                fontSize: '14px',
                                fontWeight: '600',
                                textTransform: 'uppercase',
                                lineHeight: '18px',
                                cursor: 'pointer',
                            }}
                            onClick={() => navigate('../item_group/005')}
                        >
                            Fabrics
                        </Typography>
                        {fabricsData.map((fabrics) => (
                            <Typography
                                key={fabrics.subtitle}
                                sx={{
                                    color: '#9A9A9A',
                                    fontSize: '12px',
                                    fontWeight: '600',
                                    textTransform: 'uppercase',
                                    paddingBottom: '5px',
                                    cursor: 'pointer',
                                }}
                                onClick={() => navigate(fabrics.link)}
                            >
                                {fabrics.subtitle}
                            </Typography>
                        ))}

                        {/* Wallpaper Subsection */}
                        <Box mt={2}>
                            <Typography
                                sx={{
                                    mb: 2,
                                    fontSize: '14px',
                                    fontWeight: '600',
                                    textTransform: 'uppercase',
                                    lineHeight: '18px',
                                    cursor: 'pointer',
                                }}
                                onClick={() =>
                                    navigate('../item_group/001/application/hertex wallpaper')
                                }
                            >
                                Wallpaper
                            </Typography>
                            {wallpaperData.map((wallpaper) => (
                                <Typography
                                    variant="caption"
                                    key={wallpaper.subtitle}
                                    sx={{
                                        color: '#9A9A9A',
                                        fontSize: '12px',
                                        fontWeight: '600',
                                        textTransform: 'uppercase',
                                        paddingBottom: '5px',
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => navigate(wallpaper.link)}
                                >
                                    {wallpaper.subtitle}
                                </Typography>
                            ))}
                        </Box>
                    </Box>

                    {/* Divider */}
                    <Divider
                        sx={{
                            backgroundColor: '#6E6E6E', // Preserved divider color
                            width: '1px',
                            alignSelf: 'stretch',
                            mx: { xs: 0, lg: 4 }, // Responsive horizontal margin
                            my: { xs: 4, lg: 0 }, // Responsive vertical margin
                        }}
                    ></Divider>

                    {/* Rugs Section */}
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            paddingBottom: '20px',
                        }}
                    >
                        <Typography
                            sx={{
                                mb: 2,
                                fontSize: '14px',
                                fontWeight: '600',
                                textTransform: 'uppercase',
                                lineHeight: '18px',
                                cursor: 'pointer',
                            }}
                            onClick={() => navigate('../item_group/004')}
                        >
                            Rugs
                        </Typography>
                        {rugsData.map((rugs) => (
                            <Typography
                                key={rugs.subtitle}
                                sx={{
                                    color: '#9A9A9A',
                                    fontSize: '12px',
                                    fontWeight: '600',
                                    textTransform: 'uppercase',
                                    paddingBottom: '5px',
                                    cursor: 'pointer',
                                }}
                                onClick={() => navigate(rugs.link)}
                            >
                                {rugs.subtitle}
                            </Typography>
                        ))}
                    </Box>

                    {/* Homeware Section */}
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            paddingBottom: '20px',
                        }}
                    >
                        <Typography
                            sx={{
                                mb: 2,
                                fontSize: '14px',
                                fontWeight: '600',
                                textTransform: 'uppercase',
                                lineHeight: '18px',
                                cursor: 'pointer',
                            }}
                            onClick={() => navigate('../item_group/000')}
                        >
                            Homeware
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                gap: { xs: '0', lg: '35px' },
                                justifyContent: 'space-between',
                            }}
                        >
                            <Box sx={{ width: '50%' }}>
                                {homewareData.map((homewares) => {
                                    if (homewares.section === '1') {
                                        return (
                                            <Typography
                                                key={homewares.subtitle}
                                                sx={{
                                                    color: '#9A9A9A',
                                                    fontSize: '12px',
                                                    fontWeight: '600',
                                                    textTransform: 'uppercase',
                                                    paddingBottom: '5px',
                                                    cursor: 'pointer',
                                                }}
                                                onClick={() => navigate(homewares.link)}
                                            >
                                                {homewares.subtitle}
                                            </Typography>
                                        );
                                    }
                                    return null;
                                })}
                            </Box>
                            <Box sx={{ width: '50%' }}>
                                {homewareData.map((homewares) => {
                                    if (homewares.section === '2') {
                                        return (
                                            <Typography
                                                key={homewares.subtitle}
                                                sx={{
                                                    color: '#9A9A9A',
                                                    fontSize: '12px',
                                                    fontWeight: '600',
                                                    textTransform: 'uppercase',
                                                    paddingBottom: '5px',
                                                    cursor: 'pointer',
                                                }}
                                                onClick={() => navigate(homewares.link)}
                                            >
                                                {homewares.subtitle}
                                            </Typography>
                                        );
                                    }
                                    return null;
                                })}
                            </Box>
                        </Box>
                    </Box>

                    {/* Divider */}
                    <Divider
                        sx={{
                            backgroundColor: '#6E6E6E', // Preserved divider color
                            width: '1px',
                            alignSelf: 'stretch',
                            mx: { xs: 0, lg: 4 }, // Responsive horizontal margin
                            my: { xs: 4, lg: 0 }, // Responsive vertical margin
                        }}
                    ></Divider>

                    {/* Brands Section */}
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            paddingBottom: '20px',
                        }}
                    >
                        <Typography
                            sx={{
                                mb: 2,
                                fontSize: '14px',
                                fontWeight: '600',
                                textTransform: 'uppercase',
                                lineHeight: '18px',
                                cursor: 'pointer',
                            }}
                            onClick={() => navigate('../item_group/000')}
                        >
                            Brands
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                gap: { xs: '0', lg: '35px' },
                                justifyContent: 'space-between',
                            }}
                        >
                            <Stack sx={{ flexWrap: 'wrap', maxWidth: 100 }} direction="row">
                                {brandsContent.sort().map((brand, index) => (
                                    <Typography
                                        variant="caption"
                                        key={index}
                                        sx={{
                                            color: '#9A9A9A',
                                            fontSize: '12px',
                                            fontWeight: '600',
                                            textTransform: 'uppercase',
                                            cursor: 'pointer',
                                            whiteSpace: 'nowrap',
                                            width: '100%',
                                            marginBottom: 1,
                                            ':nth-of-type(n+7)': {
                                                width: '100%',
                                                marginTop: 0,
                                                clear: 'left',
                                            },
                                        }}
                                        onClick={() => {
                                            if (brand === 'Internationals') {
                                                navigate('/internationals');
                                            } else {
                                                navigate(`/item_group/9000/brand/${brand}`);
                                            }
                                        }}
                                    >
                                        {brand}
                                    </Typography>
                                ))}
                            </Stack>
                        </Box>
                    </Box>

                    {/* Divider */}
                    <Divider
                        sx={{
                            backgroundColor: '#6E6E6E', // Preserved divider color
                            width: '1px',
                            alignSelf: 'stretch',
                            mx: { xs: 0, lg: 4 }, // Responsive horizontal margin
                            my: { xs: 4, lg: 0 }, // Responsive vertical margin
                        }}
                    ></Divider>

                    {/* Company Section */}
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            paddingBottom: '20px',
                        }}
                    >
                        <Typography
                            sx={{
                                mb: 2,
                                fontSize: '14px',
                                fontWeight: '600',
                                textTransform: 'uppercase',
                                lineHeight: '18px',
                                cursor: 'pointer',
                            }}
                            onClick={() => navigate('../item_group/000')}
                        >
                            Company
                        </Typography>
                        {companyData.map((companies) => (
                            <Typography
                                variant="caption"
                                key={companies.subtitle}
                                sx={{
                                    color: '#9A9A9A',
                                    fontSize: '12px',
                                    fontWeight: '600',
                                    textTransform: 'uppercase',
                                    paddingBottom: '5px',
                                    cursor: 'pointer',
                                }}
                                onClick={() => navigate(companies.link)}
                            >
                                {companies.subtitle}
                            </Typography>
                        ))}
                    </Box>

                    {/* Divider */}
                    <Divider
                        sx={{
                            backgroundColor: '#6E6E6E', // Preserved divider color
                            width: '1px',
                            alignSelf: 'stretch',
                            mx: { xs: 0, lg: 4 }, // Responsive horizontal margin
                            my: { xs: 4, lg: 0 }, // Responsive vertical margin
                        }}
                    ></Divider>

                    {/* Contact Section */}
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            paddingBottom: '20px',
                        }}
                    >
                        <Typography
                            sx={{
                                mb: 2,
                                fontSize: '14px',
                                fontWeight: '600',
                                textTransform: 'uppercase',
                                lineHeight: '18px',
                                cursor: 'pointer',
                            }}
                        >
                            Contact
                        </Typography>
                        {contactData.map((contact) => (
                            <Box key={contact.subtitle} pb={'20px'}>
                                <Typography
                                    sx={{
                                        color: '#9A9A9A',
                                        fontSize: '12px',
                                        fontWeight: '600',
                                        textTransform: 'uppercase',
                                        paddingBottom: '5px',
                                    }}
                                >
                                    {contact.subtitle}
                                </Typography>

                                <Typography
                                    sx={{
                                        color: '#9A9A9A',
                                        fontSize: '12px',
                                        fontWeight: '600',
                                        textTransform: 'uppercase',
                                        paddingBottom: '5px',
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => window.open(contact.link, '_blank')}
                                >
                                    {contact.text}
                                </Typography>
                            </Box>
                        ))}
                    </Box>
                </Box>


                {/* Divider */}
                <Divider
                    sx={{
                        backgroundColor: '#6E6E6E', // Preserved divider color
                        mt: 3,
                        mb: 3, // Added margin bottom for spacing
                    }}
                ></Divider>

                {/* Mobile Footer */}
                <Grid
                    container
                    direction="column"
                    sx={{
                        mt: { xs: 3, md: 3 },
                        px: { xs: 2, md: 4 }, // Preserved horizontal padding
                    }}
                    alignItems="stretch" // Ensure items take full width
                >
                    {/* Disclaimer Text */}
                    <Grid item sx={{ width: '100%', maxWidth: '1200px' }}>
                      <Typography
                        variant="caption"
                        fontWeight="600"
                        color="#FFF"
                        component="div"
                        sx={{
                          whiteSpace: 'pre-line',
                          textAlign: 'left',
                        }}
                      >
                        <div> <strong>Important Notice:</strong>All content, including images, text, and designs on this website, is protected by
                          copyright. Unauthorized use, reproduction, or distribution without written permission is
                          strictly prohibited.
                        </div>
                        <div><strong>Hertex trade customers enjoy exclusive access to content assets provided for
                          download in the RESOURCES section of this portal.</strong></div>
                        <div><strong>T's & C's apply:</strong></div>
                      </Typography>
                        <Typography
                            variant="caption"
                            fontWeight="600"
                            color="#FFF"
                            paragraph
                            sx={{
                                whiteSpace: 'pre-line',
                                textAlign: 'left', // Left-align text
                            }}
                        >
                            <strong>Hertex fabric and wallcoverings:</strong> Product and lifestyle images are available for download and may be used for commercial purposes, such as posting on registered Hertex trade customers’ official websites and social media channels; moodboards; presentations; proposals; or tenders that promote and/or quote the Hertex product – provided that Hertex is credited. Altering and/or reproducing images is strictly prohibited.
                        </Typography>
                        <Typography
                            variant="caption"
                            fontWeight="600"
                            color="#FFF"
                            paragraph
                            sx={{
                                whiteSpace: 'pre-line',
                                textAlign: 'left', // Left-align text
                            }}
                        >
                            <strong>HAUS rugs, furniture, and homeware:</strong> Product images are available for download and may be used for commercial purposes, such as posting on registered Hertex trade customers’ official websites and social media channels; moodboards; presentations; proposals; or tenders that promote and/or quote the product – provided that HAUS is credited. HAUS product images may be used to create product renders. Altering and/or reproducing images is strictly prohibited. HAUS lifestyle images are for viewing purposes only. Downloading, reproducing, distributing, or using these images without explicit written permission from HAUS is strictly prohibited.
                        </Typography>
                        <Typography
                            variant="caption"
                            fontWeight="600"
                            color="#FFF"
                            sx={{
                                whiteSpace: 'pre-line',
                                textAlign: 'left', // Left-align text
                            }}
                        >
                            Contact{' '}
                            <Link href="mailto:marketingassistant@hertex.co.za" color="#FFF" underline="always">
                                marketingassistant@hertex.co.za
                            </Link>{' '}
                            if you require assistance. © {dateNow.getFullYear()} Hertex. All rights reserved.
                        </Typography>
                    </Grid>

                    {/* Reserved Rights */}


                    {/* Social Media Icons */}
                    <Grid item sx={{ mt: 2 }}>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center', // Center the icons
                                gap: 2, // Space between icons
                            }}
                        >
                            <Link
                                href="https://www.facebook.com/hertexfabrics/"
                                target="_blank"
                                rel="noopener noreferrer"
                                aria-label="Facebook"
                                sx={{ color: '#FFF' }}
                            >
                                <FacebookIcon sx={{ fontSize: 24 }} />
                            </Link>
                            <Link
                                href="https://www.linkedin.com/company/hertexfabrics/mycompany/"
                                target="_blank"
                                rel="noopener noreferrer"
                                aria-label="LinkedIn"
                                sx={{ color: '#FFF' }}
                            >
                                <LinkedInIcon sx={{ fontSize: 24 }} />
                            </Link>
                            <Link
                                href="https://www.instagram.com/hertexfabrics/"
                                target="_blank"
                                rel="noopener noreferrer"
                                aria-label="Instagram"
                                sx={{ color: '#FFF' }}
                            >
                                <InstagramIcon sx={{ fontSize: 24 }} />
                            </Link>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Grid>
    );
}

export default Footer;
