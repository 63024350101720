import { createContext, useContext, useState, useRef, useEffect } from 'react';
import Keycloak from 'keycloak-js';
import { ReactNode } from 'react';
import { useQuery } from 'react-query';
import { apiKeycloakConfig } from '../api/keycloakConfig';
import { jwtDecode } from 'jwt-decode'; // Import the named export

type User = {
  customAttribute1: string;
  email: string;
  email_verified: boolean;
  family_name: string;
  given_name: string;
  name: string;
  preferred_username: string;
  sub: string;
  attr?: {
    paymentTerms?: string;
    accountNo?: string;
    [key: string]: any;
  };
};

type AuthContextType = {
  login: () => void;
  logout: () => void;
  setIsLogin: (isLogin: boolean) => void;
  isLogin: boolean;
  setUser: (user: User | {} | undefined) => void;
  user: User | {} | undefined;
  setIsAccountOnHold: (isAccountOnHold: boolean) => void;
  isAccountOnHold: boolean;
  keycloak: any;
  paymentTerms: string | null;
  accountNo: string | null;
};

type AuthProviderProps = {
  children: ReactNode;
};

const AuthContext = createContext({} as AuthContextType);

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [keycloak, setKeycloak] = useState<any>();
  const [paymentTerms, setPaymentTerms] = useState<string | null>(null);
  const [accountNo, setAccountNo] = useState<string | null>(null);

  const { data: configData } = useQuery(
    [`keycloak-config`],
    () => apiKeycloakConfig(),
    {
      onSuccess: (data) => {
        const keycloakInstance = new Keycloak({
          url: data.url,
          realm: data.realm,
          clientId: data.clientId,
        });
        setKeycloak(keycloakInstance);
      },
      refetchOnWindowFocus: false,
    }
  );

  const [isLogin, setIsLogin] = useState<boolean>(false);
  const [user, setUser] = useState<User | {} | undefined>();
  const [isAccountOnHold, setIsAccountOnHold] = useState<boolean>(false);

  // ! We are using use ref here to ensure the useEffect only runs once
  const isRun = useRef(false);

  const token = keycloak?.token;

  // Store token in localStorage
  localStorage.setItem('authToken', token || '');

  // Extract JWT token info when the token changes
  useEffect(() => {
    if (token) {
      try {
        // Decode the JWT token
        const decodedToken = jwtDecode(token);

        // Extract payment terms and account number from attr if available
        const attr = (decodedToken as any).attr || {};

        // Set payment terms
        if (attr.paymentTerms) {
          setPaymentTerms(attr.paymentTerms);
          localStorage.setItem('paymentTerms', attr.paymentTerms);
        }

        // Set account number if available
        if (attr.accountNo) {
          setAccountNo(attr.accountNo);
          localStorage.setItem('accountNo', attr.accountNo);
        }

        // You could also add the attr to the user object if needed
        if (user && typeof user === 'object' && !('attr' in user)) {
          setUser({ ...user, attr });
        }
      } catch (error) {
        console.error('Error decoding JWT token:', error);
      }
    }
  }, [token, user]);

  const login = () => {
    keycloak?.login();
  };

  const logout = () => {
    setIsLogin(false);
    localStorage.removeItem('paymentTerms');
    localStorage.removeItem('accountNo');
    keycloak?.logout({ redirectUri: configData?.redirectUri });
  };

  useEffect(() => {
    if (!isRun.current && keycloak) {
      keycloak
        .init({
          onLoad: 'login-required',
        })
        .then((res: boolean | ((prevState: boolean) => boolean)) => {
          setIsLogin(res);
          if (res) {
            keycloak
              .loadUserInfo()
              .then((user: User | {} | undefined) => {
                setUser(user);
                localStorage.setItem(
                  'user',
                  JSON.stringify(user)
                );
              });
          }
        });
      isRun.current = true;
    }
  }, [keycloak]);

  return (
    <AuthContext.Provider
      value={{
        setIsLogin,
        isLogin,
        login,
        logout,
        setUser,
        user,
        setIsAccountOnHold,
        isAccountOnHold,
        keycloak,
        paymentTerms,
        accountNo,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);