import { useNavigate } from 'react-router-dom';
import { Box, Grid, Typography } from '@mui/material';
import { CategorySettings, PromoProduct } from '../pageTemplates/promo/types';

interface DesignsInCollectionGridProps {
  data: any[];
  marginBottom?: string | number;
  marginTop?: string | number;
  promoProducts?: PromoProduct[];
  categorySettings?: CategorySettings;
}

const DesignsInCollectionGrid = ({
                                   data,
                                   marginBottom,
                                   marginTop,
                                   promoProducts = [],
                                   categorySettings
                                 }: DesignsInCollectionGridProps) => {
  const navigate = useNavigate();
  data = data?.filter((item: any) => item?.Product && item?.StockMaster);

  return (
    <Box
      sx={{
        mt: marginTop,
        mb: marginBottom,
        ml: { xs: '5%', lg: '5rem' },
        mr: { xs: '5%', lg: '5rem' },
      }}
    >
      <Typography
        variant='h4'
        mb={2}
        fontWeight='400'
        fontSize={'56px'}
      >
        {`Designs in this collection`}
      </Typography>

      <Typography mt={1} mb={2} fontWeight='bold'></Typography>

      <Grid container spacing={'12px'}>
        {data
          ?.sort((a: any, b: any) =>
            a.StockMaster?.design
              .toLowerCase()
              .localeCompare(
                b.StockMaster?.design.toLowerCase()
              )
          )
          .map((elem: any, index: number) => {
            // Check if this product is in promoProducts
            const promoProduct = promoProducts.find(
              promo =>
                promo.product_sku === elem.Product?.sku &&
                promo.size === elem.StockMaster?.web_size
            );

            // Construct size string based on diameter or width x length
            const sizeString = elem.StockMaster?.diameter && elem.StockMaster.diameter !== '0cm'
              ? elem.StockMaster.diameter.trim()
              : `${elem.StockMaster?.width?.trim()}x${elem.StockMaster?.length_cm?.trim()}`;

            // Update this part in DesignsInCollectionGrid where we construct the productUrl:
            const productUrl = promoProduct
              ? `/item_group/${elem.StockMaster?.item_group}/application/${encodeURIComponent(
                elem.StockMaster?.application
              )}/collection/${encodeURIComponent(
                elem.StockMaster?.range
              )}/design/${encodeURIComponent(
                elem.StockMaster?.design
              )}/colour/${encodeURIComponent(
                elem.StockMaster?.colour
              )}?promoId=${promoProduct.id}&targetSize=${
                encodeURIComponent(sizeString)
              }&targetProduct=${
                encodeURIComponent(elem.Product?.sku)
              }&overlayText=${
                encodeURIComponent(categorySettings?.items_overlay_text || 'SALE')
              }&overlayColor=${
                encodeURIComponent(categorySettings?.items_overlay_text_colour || '#FFFFFF')
              }&overlaySize=${
                categorySettings?.items_overlay_text_size || 14
              }`
              : `/item_group/${elem.StockMaster?.item_group}/application/${encodeURIComponent(
                elem.StockMaster?.application
              )}/collection/${encodeURIComponent(
                elem.StockMaster?.range
              )}/design/${encodeURIComponent(
                elem.StockMaster?.design
              )}/colour/${encodeURIComponent(
                elem.StockMaster?.colour
              )}?targetSize=${encodeURIComponent(sizeString)}&targetProduct=${encodeURIComponent(elem.Product?.sku)}`; // Add these parameters

            return (
              <Grid key={index} item xs={12} sm={6} lg={2}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    width: { xs: '100%', lg: '100%' },
                  }}
                >
                  <Box
                    sx={{
                      cursor: 'pointer',
                      height: '350px',
                      width: '100%',
                      position: 'relative',
                      '&:hover img': {
                        transform: 'scale(1.03)',
                      },
                      overflow: 'hidden',
                    }}
                    onClick={() => {
                      navigate(productUrl);
                    }}
                  >
                    {/* Show promo overlay if it's a promo product */}
                    {promoProduct && categorySettings && (
                      <Box
                        sx={{
                          position: 'absolute',
                          bottom: 0,
                          left: 0,
                          bgcolor: categorySettings.items_overlay_text_colour || '#FFFFFF',
                          color: '#FFFFFF',
                          px: 2,
                          py: 0.75,
                          zIndex: 1,
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: `${categorySettings.items_overlay_text_size || 14}px`,
                            fontWeight: 600,
                          }}
                        >
                          {categorySettings.items_overlay_text || 'SALE'}
                        </Typography>
                      </Box>
                    )}
                    <img
                      src={elem?.Product?.primaryImageUrl}
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        transition: 'transform 0.5s ease',
                      }}
                      alt={`${elem.StockMaster?.design} in ${elem.Product?.colour}`}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      width: '100%',
                      flexDirection: 'column',
                    }}
                  >
                    <Typography
                      mt={1}
                      fontWeight={'600'}
                      fontSize={'18px'}
                      textTransform={'capitalize'}
                      lineHeight={'27px'}
                    >
                      {elem?.StockMaster?.design}
                    </Typography>
                    <Typography
                      fontWeight={'400'}
                      fontSize={'16px'}
                      textTransform={'capitalize'}
                      lineHeight={'27px'}
                    >
                      {elem?.Product?.colour}
                    </Typography>
                    {elem?.StockMaster?.item_group === '004' && (
                      <Typography
                        mb={2}
                        fontWeight={'400'}
                        fontSize={'16px'}
                        lineHeight={'27px'}
                      >
                        {sizeString}
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Grid>
            );
          })}
      </Grid>
    </Box>
  );
};

export default DesignsInCollectionGrid;