import React, { useEffect, useState } from 'react';
import {
  Container,
  Grid,
  Typography,
  Box,
  useTheme,
  useMediaQuery
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { apiGetPromoLayout, apiGetAllPromoProducts } from "../../../../../api/apiPromos";
import LoadingScreen from "../../../loadingScreen/LoadingScreen";
import PromoBanner from "./PromoCarousel";
import {
  PromoLayout,
  CategorySettings,
  MainSettingsResponse,
  CarouselItem
} from "../types";

const CategoryTitle = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(2),
  fontWeight: 600,
  fontSize: '32px', // Default size for mobile
  letterSpacing: '0.02em',
  [theme.breakpoints.up('sm')]: {
    fontSize: '56px' // 56px for normal screens
  }
}));

const CategorySubtitle = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  fontWeight: 500,
  fontSize: '24px', // Default size for mobile
  letterSpacing: '0.01em',
  [theme.breakpoints.up('sm')]: {
    fontSize: '32px' // 32px for normal screens
  }
}));

// No hardcoded category lists - we'll use all categories from settings that have matching promo products

const PromoContent: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [bannerLayout, setBannerLayout] = useState<PromoLayout | null>(null);
  const [fabricCategories, setFabricCategories] = useState<CategorySettings[]>([]);
  const [homewareCategories, setHomewareCategories] = useState<CategorySettings[]>([]);
  const [categoryWithProducts, setCategoryWithProducts] = useState<Record<string, boolean>>({});
  // Store all category groups
  const [categoryGroups, setCategoryGroups] = useState<Record<string, CategorySettings[]>>({});

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch the promo layout
        const response: MainSettingsResponse = await apiGetPromoLayout(1);

        // Fetch all promo products to check which categories have products
        const allPromoProductsResponse = await apiGetAllPromoProducts();
        const allPromoProducts = allPromoProductsResponse.promo_products || [];

        // Create a map of application -> has products
        const applicationsWithProducts: Record<string, boolean> = {};

        // Extract unique applications from the promo products
        allPromoProducts.forEach((product: any) => {
          if (product.application) {
            applicationsWithProducts[product.application] = true;
          }
        });

        if (response && response.main_settings) {
          setBannerLayout({
            banner_text: response.main_settings.landing_page_banner_text,
            banner_text_colour: response.main_settings.landing_page_banner_text_color,
            banner_text_size: response.main_settings.landing_page_banner_text_size,
            banner_sub_text: response.main_settings.landing_page_banner_sub_text,
            banner_sub_text_colour: response.main_settings.landing_page_banner_sub_text_color,
            banner_sub_text_size: response.main_settings.landing_page_banner_sub_text_size,
            banner_date_text: response.main_settings.landing_page_banner_date_text,
            banner_date_text_color: response.main_settings.landing_page_banner_date_text_color,
            banner_date_text_size: response.main_settings.landing_page_banner_date_text_size,
            banner_img: response.main_settings.landing_page_img,
            banner_url: response.main_settings.landing_page_banner_url,
            banner_size_x: response.main_settings.landing_page_banner_size_x,
            banner_size_y: response.main_settings.landing_page_banner_size_y,
            is_active: response.main_settings.is_active,
            is_live: response.main_settings.is_live,
            banner_media_type: response.main_settings.landing_page_banner_media_type,
            landing_page_banner_media_type: response.main_settings.landing_page_banner_media_type,
            landing_page_video: response.main_settings.landing_page_video,
            landing_page_video_name: response.main_settings.landing_page_video_name,
            landing_page_carousel_data: response.main_settings.landing_page_carousel_data,
            banner_carousel_data: response.main_settings.landing_page_carousel_data
          } as PromoLayout);



          // Filter all categories that have matching promo products - fully dynamic approach
          const categoriesWithPromoProducts = response.main_settings.category_settings
            .filter(category =>
              category.is_active === 1 &&
              category.is_deleted === 0 &&
              applicationsWithProducts[category.category_name] === true
            );



          // Get categories by greater_group_name
          const fabricCategories = categoriesWithPromoProducts.filter(
            category => category.greater_group_name === 'Fabric'
          );

          const homewareCategories = categoriesWithPromoProducts.filter(
            category => category.greater_group_name === 'Homeware'
          );

          // Set states with found categories
          setFabricCategories(fabricCategories);
          setHomewareCategories(homewareCategories);
          setCategoryWithProducts(applicationsWithProducts);



          // Create groups based on greater_group_name - fully dynamic from all categories with promo products
          const groups: Record<string, CategorySettings[]> = {};

          // Group all categories with promo products by their greater_group_name
          categoriesWithPromoProducts.forEach(category => {
            const greaterGroupName = category.greater_group_name || 'Other';
            if (!groups[greaterGroupName]) {
              groups[greaterGroupName] = [];
            }
            groups[greaterGroupName].push(category);
          });



          setCategoryGroups(groups);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('An error occurred while fetching data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleCategoryClick = (category: CategorySettings): void => {
    const params = new URLSearchParams();

    // Special case for Scatters category - use "Cushion Inners" as the application
    const applicationValue = category.category_name === 'Scatters' ? 'Cushion Inners' : category.category_name;

    // Add primary application
    params.append('application', applicationValue);

    // Add Dual Purpose for Curtaining and Upholstery
    if (applicationValue === 'Curtaining' || applicationValue === 'Upholstery') {
      params.append('application', 'Dual Purpose');
    }

    // Add other parameters
    params.append('page', '1');
    params.append('limit', '9');

    window.location.href = `/promo/application/${encodeURIComponent(applicationValue)}?itemGroup=${category.item_group}&${params.toString()}`;
  };

  const renderCategoryCard = (category: CategorySettings, hasPromoProducts: boolean) => {
    // Parse carousel data with the correct typing
    let carouselData: CarouselItem[] = [];
    let mediaUrl = '';



    try {
      if (category.tile_carousel_data && typeof category.tile_carousel_data === 'string') {
        // If it's a string (from backend JSON serialization), parse it

        const parsedData = JSON.parse(category.tile_carousel_data as unknown as string);


        if (Array.isArray(parsedData)) {
          carouselData = parsedData;
        }
      } else if (Array.isArray(category.tile_carousel_data)) {
        // If it's already an array, use it directly
        carouselData = category.tile_carousel_data;
      }

      // Get the first media URL from carousel data if available
      mediaUrl = carouselData.length > 0 ? carouselData[0]?.media_url : '';

    } catch (error) {
      console.error('Error parsing carousel data for category', category.category_name, error);
    }

    // If no media URL is found or parsing failed, use a fallback image
    if (!mediaUrl) {
      // Use tile_img if available, otherwise use a fallback
      mediaUrl = category.tile_img || '/fallback-category-image.jpg';

    }

    return (
      <Box
        sx={{
          position: 'relative',
          cursor: 'pointer',
          width: '100%',
          height: { xs: '320px', lg: '510px' },
          minHeight: '100%',
          overflow: 'hidden',
          '&:hover img': {
            transform: 'scale(1.03)',
          },
          '&:hover video': {
            transform: 'scale(1.03)',
          },
        }}
        onClick={() => handleCategoryClick(category)}
      >
        {category.tile_media_type === 'video' && category.tile_video ? (
          <video
            autoPlay
            muted
            loop
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              transition: 'transform 0.5s ease',
            }}
          >
            <source src={category.tile_video} type="video/mp4" />
            Your browser does not support video playback.
          </video>
        ) : (
          <img
            src={mediaUrl || ''}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              transition: 'transform 0.5s ease',
            }}
            alt={category.category_name}
            onError={(e: React.SyntheticEvent<HTMLImageElement>) => {
              const img = e.currentTarget;
              img.src = '/fallback-image.jpg';
              img.onerror = null;
            }}
          />
        )}

        <Box
          sx={{
            position: 'absolute',
            bottom: 0,
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '20px'
          }}
        >
          <Typography
            variant="h6"
            sx={{
              textAlign: 'center',
              fontWeight: 'lighter',
              color: '#fff',
              fontSize: '36px',
              textTransform: 'capitalize',
              mb: { xs: 1, lg: 2 },
              textShadow: '2px 2px 4px rgba(0,0,0,0.7)' // Add text shadow for better visibility
            }}
          >
            {category.tile_text || category.category_name}
          </Typography>
        </Box>
      </Box>
    );
  };

  const renderPlaceholderCard = () => (
    <Box
      sx={{
        height: { xs: '320px', lg: '510px' },
        visibility: 'hidden'
      }}
    />
  );

  // New function to render category groups in the specified order with alphabetical sorting within groups
  const renderCategoryGroups = () => {
    // Define the specific order we want for greater group names
    const groupOrder = ['Fabric', 'Wallpaper', 'Rugs', 'Homeware'];

    // Filter out any groups that don't exist in our data
    const orderedGroups = groupOrder.filter(groupName => categoryGroups[groupName]);

    return orderedGroups.map(groupName => {
      const categories = categoryGroups[groupName];

      // Skip groups with no categories that have products
      const categoriesWithProducts = categories.filter(
        category => categoryWithProducts[category.category_name]
      );

      if (categoriesWithProducts.length === 0) {
        return null;
      }

      // Special handling for Homeware - show item_group_name as subtitles
      if (groupName === 'Homeware') {
        // Group homeware categories by item_group_name instead of category_name
        const itemGroupSubgroups: Record<string, CategorySettings[]> = {};

        categoriesWithProducts.forEach(category => {
          // Use item_group_name as the grouping key
          const itemGroupName = category.item_group_name || 'Other';
          if (!itemGroupSubgroups[itemGroupName]) {
            itemGroupSubgroups[itemGroupName] = [];
          }
          itemGroupSubgroups[itemGroupName].push(category);
        });

        // Sort each subgroup of categories alphabetically by category_name
        Object.keys(itemGroupSubgroups).forEach(itemGroupName => {
          itemGroupSubgroups[itemGroupName].sort((a, b) =>
            (a.category_name || '').localeCompare(b.category_name || '')
          );
        });

        // Get item group names and sort them alphabetically
        const sortedItemGroupNames = Object.keys(itemGroupSubgroups).sort((a, b) => a.localeCompare(b));

        return (
          <Box key={groupName} mb={6}>
            <CategoryTitle variant="h5">
              {groupName}
            </CategoryTitle>

            {/* Render each item_group as a subgroup in alphabetical order */}
            {sortedItemGroupNames.map(itemGroupName => (
              <Box key={`${groupName}-${itemGroupName}`} mb={4}>
                <CategorySubtitle variant="h6">
                  {itemGroupName}
                </CategorySubtitle>
                <Grid
                  container
                  rowSpacing={'16px'}
                  columnSpacing={{ xs: 1, sm: 2, md: '16px' }}
                >
                  {itemGroupSubgroups[itemGroupName].map((category, index) => (
                    <Grid item xs={12} sm={6} key={`${itemGroupName}-${index}`}>
                      {renderCategoryCard(
                        category,
                        categoryWithProducts[category.category_name] || false
                      )}
                    </Grid>
                  ))}
                </Grid>
              </Box>
            ))}
          </Box>
        );
      } else {
        // For non-Homeware categories, sort them alphabetically by category_name
        const sortedCategories = [...categoriesWithProducts].sort((a, b) =>
          (a.category_name || '').localeCompare(b.category_name || '')
        );

        return (
          <Box key={groupName} mb={6}>
            <CategoryTitle variant="h5">
              {groupName}
            </CategoryTitle>
            <Grid
              container
              rowSpacing={'16px'}
              columnSpacing={{ xs: 1, sm: 2, md: '16px' }}
            >
              {sortedCategories.map((category, index) => (
                <Grid item xs={12} sm={6} key={`${groupName}-${index}`}>
                  {renderCategoryCard(
                    category,
                    categoryWithProducts[category.category_name] || false
                  )}
                </Grid>
              ))}
            </Grid>
          </Box>
        );
      }
    });
  };

  if (loading) return <LoadingScreen />;
  if (error) {
    return (
      <Container maxWidth="lg" sx={{ py: 4 }}>
        <Typography variant="h6" color="error">{error}</Typography>
      </Container>
    );
  }

  return (
    <>
      {bannerLayout && (
        <Box sx={{ width: '100%', overflow: 'hidden' }}>
          <PromoBanner layout={bannerLayout} />
        </Box>
      )}

      <Container maxWidth="xl" sx={{ py: 4 }}>
        {renderCategoryGroups()}
      </Container>
    </>
  );
};

export default PromoContent;