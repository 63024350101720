import { cartBaseURL } from './baseUrl';

// Silent request wrapper - handles all errors quietly
const makeSilentRequest = async (requestFn: () => Promise<any>) => {
  try {
    const response = await requestFn();
    return response.data;
  } catch (error) {
    console.error('API request failed:', error);
    return null;
  }
};

// initialize / Get cart
export const getCartByUserId = async (authToken: string, status_check?: object) => {
  const response = await makeSilentRequest(() =>
    cartBaseURL.get('/carts', {
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
    })
  );
  return response.cart;
};



export const setItemAmountInCart = async (
  authToken: string,
  cartId: string,
  sku: string,
  quantity: number
) => {
  return cartBaseURL.post(`/carts/${cartId}/items/${sku}/${quantity.toString()}`, null, {
    headers: {
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'application/json',
    },
  });
};



export const editItemAmountInCart = async (
  authToken: string,
  cartId: string,
  sku: string,
  quantityChange: number
) => {
  try {
    const response = await cartBaseURL.put(`/carts/${cartId}/items/${sku}/${quantityChange.toString()}`, null, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
    });

    // Return the complete response data
    return response.data;
  } catch (error: any) { // Add type annotation for error
    console.error('Error editing item amount in cart:', error);

    // Safe access to error response data
    if (error && typeof error === 'object') {
      // Check for Axios error structure
      if (error.response && typeof error.response === 'object' && error.response.data) {
        return error.response.data;
      }

      // If error has a message property, include it
      if (error.message) {
        return { success: false, msg: `Failed to update quantity: ${error.message}` };
      }
    }

    // Default error response
    return { success: false, msg: 'Failed to update quantity' };
  }
};


export const removeItemFromCart = async (
  authToken: string,
  cartId: string,
  sku: string
) => {
  return makeSilentRequest(() =>
    cartBaseURL.delete(`/carts/${cartId}/items/${sku}`, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
    })
  );
};

export const clearCart = async (
  authToken: string,
  cartId: string
) => {
  return makeSilentRequest(() =>
    cartBaseURL.delete(`/carts/${cartId}/clear`, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
    })
  );
};

// info update
export const updateDeliveryInfo = async (
  authToken: string,
  cartId: string,
  deliveryInfo: {
    shipping_address: {
      address_line_1: string;
      address_line_2?: string;
      address_line_3?: string;
      address_line_4?: string;
      address_line_5?: string;
      address_zip: string;
    };
    delivery_type: 'COLLECTION' | 'DELIVERY' | 'THIRD_PARTY_DELIVERY';
    delivery_note?: string;
    customer_order_num?: string;
    contact_person: {
      name: string;
      surname: string;
      phone: string;
    };
    payment_type: '30E' | 'PIA';
  }
) => {
  return makeSilentRequest(() =>
    cartBaseURL.post(`/carts/${cartId}/delivery-info`, deliveryInfo, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
    })
  );
};

// payments
export const finalizeCartWithAccount = async (cartId: string, authToken: string) => {
  return makeSilentRequest(() =>
    cartBaseURL.post(`/carts/${cartId}/payment/account`, null, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
    })
  );
};

export const finalizeCartWithPayfast = async (cartId: string, authToken: string) => {
  // The problem with makeSilentRequest is it might be silently catching errors
  // or modifying the response structure
  try {
    const response = await cartBaseURL.post(`/carts/${cartId}/payment/payfast`, null, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
    });
    // Return the complete response data which should contain payment_form
    return response.data;
  } catch (error) {
    console.error('Error finalizing cart with PayFast:', error);
    return null;
  }
};

export const getCartPaymentStatus = async (cartId: string, authToken: string) => {
  return makeSilentRequest(() =>
    cartBaseURL.get(`/carts/${cartId}/payment`, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
    })
  );
};



// checked
export const updateCartStatus = async (
  cartId: string,
  authToken: string,
  status: string
) => {
  return makeSilentRequest(() =>
    cartBaseURL.put(`/carts/${cartId}/status/${status}`, null, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
    })
  );
};



// Updated to use new endpoint for user-specific pricing
export const getUserPricingForProduct = async (authToken: string, accountNo: string, stockLink: string) => {
  return makeSilentRequest(() =>
    cartBaseURL.get(`/users/${accountNo}/pricing/${stockLink}`, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
    })
  );
};

// Keeping the old discount function for backward compatibility, but it will call the new endpoint
export const discount = async (authToken: string, stockLink: number) => {
  // Extract account number from token or context
  // For now, using "current" as a placeholder - you may need to adjust based on how you get accountNo
  const accountNo = "current";

  return getUserPricingForProduct(authToken, accountNo, stockLink.toString());
};

// checked
export const userCredit = async (authToken: string) => {
  // Since the backend extracts user ID from the token,
  // we can use "current" as a placeholder in the URL
  return makeSilentRequest(() =>
    cartBaseURL.get('/users/current/credit', {
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
    })
  );
};

export const paymentStatus = async (authToken: string, cartId: string) => {
  return makeSilentRequest(() =>
    cartBaseURL.get(`/carts/${cartId}/status`, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
    })
  );
};


export const getStockForItem = async (authToken: string, sku: string, cartId?: string) => {
  console.log('Checking stock for SKU:', sku, 'Cart ID:', cartId);

  return makeSilentRequest(() =>
    cartBaseURL.get(`/stock-check/${sku}${cartId ? `?cart_id=${cartId}` : ''}`, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
    })
  );
};