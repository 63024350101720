import React, { useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import LastPageIcon from '@mui/icons-material/LastPage';
import FirstPageIcon from '@mui/icons-material/FirstPage';

interface PromoPaginationProps {
    totalPages: number;
    currentPage: number;
    previousPage: () => void;
    nextPage: () => void;
    productItemCount: number;
    lastPage: () => void;
    firstPage: () => void;
    currentProducts?: any[]; // Optional prop to pass current products array
}

const PromoPagination: React.FC<PromoPaginationProps> = ({
                                                             totalPages,
                                                             currentPage,
                                                             previousPage,
                                                             nextPage,
                                                             productItemCount,
                                                             lastPage,
                                                             firstPage,
                                                             currentProducts
                                                         }) => {
    // Ensure totalPages is at least 1
    const displayTotalPages = Math.max(1, Math.ceil(totalPages));

    // Ensure currentPage is within valid range
    const displayCurrentPage = Math.min(Math.max(1, currentPage), displayTotalPages);

    // Determine the product count
    const displayProductCount = productItemCount > 0
        ? productItemCount
        : (currentProducts?.length || 0);

    // Debug log to help identify the source of the count discrepancy
    useEffect(() => {

    }, [totalPages, currentPage, productItemCount, currentProducts]);

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                flexDirection: { xs: 'column', sm: 'row' },
                ml: 0,
                mb: 1,
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <Typography mr={0}>
                    Page {displayCurrentPage} of {displayTotalPages}
                </Typography>

                <Box
                    sx={{
                        ml: 1,
                    }}
                >
                    <FirstPageIcon
                        fontSize='small'
                        sx={{
                            cursor: currentPage > 1 ? 'pointer' : 'default',
                            ml: 2,
                            opacity: currentPage > 1 ? 1 : 0.3,
                        }}
                        onClick={() => {
                            if (currentPage > 1) firstPage();
                        }}
                    />
                    <ArrowBackIosIcon
                        fontSize='small'
                        sx={{
                            cursor: currentPage > 1 ? 'pointer' : 'default',
                            ml: 2,
                            opacity: currentPage > 1 ? 1 : 0.3,
                        }}
                        onClick={() => {
                            if (currentPage > 1) previousPage();
                        }}
                    />

                    <ArrowForwardIosIcon
                        fontSize='small'
                        sx={{
                            cursor: currentPage < displayTotalPages ? 'pointer' : 'default',
                            ml: 2,
                            opacity: currentPage < displayTotalPages ? 1 : 0.3,
                        }}
                        onClick={() => {
                            if (currentPage < displayTotalPages) nextPage();
                        }}
                    />
                    <LastPageIcon
                        fontSize='small'
                        sx={{
                            cursor: currentPage < displayTotalPages ? 'pointer' : 'default',
                            ml: 2,
                            opacity: currentPage < displayTotalPages ? 1 : 0.3,
                        }}
                        onClick={() => {
                            if (currentPage < displayTotalPages) lastPage();
                        }}
                    />
                </Box>
            </Box>
            <Typography pr={0}>
                Total: <b>{displayProductCount}</b>
            </Typography>
        </Box>
    );
};

export default PromoPagination;